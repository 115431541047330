import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ConclusaoObra } from "../../../../../shared/interfaces/IProperties";
import { PropertiesApiService } from "../../../../../shared/services/api/properties-api.service";
import { ApiErrorHandler } from "../../../../../shared/utils/errors.utils";

interface Props {
    subscription?: string;
}

export const Cvco: React.FC<Props> = ({ subscription }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchedCvco, setFetchedCvco] = useState<ConclusaoObra>();

    const fetchAlvaraInfo = useCallback(async (subscription_: string) => {
        try {
            setLoading(true);
            const cvco = await PropertiesApiService.getPropertyCvco(
                subscription_
            );

            setFetchedCvco(cvco);
        } catch (err) {
            ApiErrorHandler(err, "warn");
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (subscription && subscription.length > 0) {
            fetchAlvaraInfo(subscription);
        }
    }, [fetchAlvaraInfo, subscription]);

    return (
        <>
            <Row>
                <h4>Informações Alvará de Obras</h4>
            </Row>
            {!loading && (
                <div>
                    <Col>
                        <Form.Label>Número</Form.Label>
                        <Form.Control disabled value={fetchedCvco?.numero} />
                    </Col>

                    <Col>
                        <Form.Label>Ano</Form.Label>
                        <Form.Control disabled value={fetchedCvco?.ano} />
                    </Col>

                    <Col>
                        <Form.Label>Área</Form.Label>
                        <Form.Control disabled value={fetchedCvco?.area} />
                    </Col>

                    <Col>
                        <Form.Label>Tipo de vistoria</Form.Label>
                        <Form.Control
                            disabled
                            value={fetchedCvco?.tipovistoria}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Área concluída</Form.Label>
                        <Form.Control
                            disabled
                            value={fetchedCvco?.areaconcluida}
                        />
                    </Col>
                </div>
            )}
        </>
    );
};
