import React from "react";

import { Routes as RouterRoutes, Route } from "react-router-dom";

import { Webgeo, Errors } from "../views";

import Login from "../views/grp/login";
import Menus from "../views/grp/menus";
import { ProtectedRoutes } from "./protected.routes";

import { RoutesConfig } from "../config/grp/RoutesConfig";

export const Routes: React.FC = () => {
    return (
        <RouterRoutes>
            <Route path="/" element={<Webgeo.WebgeoHome />} />
            <Route path="/cemiterio" element={<Webgeo.CemeteryHome />} />
            <Route path="/active-user" element={<Webgeo.WebgeoActiveUser />} />
            <Route
                path="/reset-password"
                element={<Webgeo.WebgeoResetPassword />}
            />
            <Route
                path="/consulta-previa/:subscription"
                element={<Webgeo.WebgeoConsultaPrevia />}
            />
            <Route
                path="/confrontante/:subscription"
                element={
                    <ProtectedRoutes>
                        <Webgeo.WebgeoConfrontante />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/bci/:subscription"
                element={
                    <ProtectedRoutes>
                        <Webgeo.WebgeoBci />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/bcm/:subscription"
                element={
                    <ProtectedRoutes>
                        <Webgeo.Bcm />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/autorizacao-abertura-tumulo/:subscription/:deceased"
                element={
                    <ProtectedRoutes>
                        <Webgeo.AutorizacaoAberturaTumulo />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/modelo-de-posse/:subscription"
                element={
                    <ProtectedRoutes>
                        <Webgeo.ModeloPosse />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/translado-interno/:subscription/:deceasedsubscription"
                element={
                    <ProtectedRoutes>
                        <Webgeo.TransladoInterno />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/translado-externo/:subscription/:deceasedsubscription"
                element={
                    <ProtectedRoutes>
                        <Webgeo.TransladoExterno />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/translado-envio/:subscription/:deceasedsubscription"
                element={
                    <ProtectedRoutes>
                        <Webgeo.CemeteryTransladoEnvio />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/autorizacao-empresa/:subscription/:worker"
                element={
                    <ProtectedRoutes>
                        <Webgeo.PrestadorEmpresa />
                    </ProtectedRoutes>
                }
            />
            <Route
                path="/autorizacao-proprietario/:subscription/:worker"
                element={
                    <ProtectedRoutes>
                        <Webgeo.PrestadorProprietario />
                    </ProtectedRoutes>
                }
            />

            <Route path="/grp/login" element={<Login />} />
            <Route path="/grp" element={<ProtectedRoutes restricted />}>
                {RoutesConfig.protected.modules.map((module) => (
                    <Route
                        path={module.path}
                        element={
                            module.element ? <module.element /> : <Menus />
                        }
                        key={module.key}
                    />
                ))}

                {RoutesConfig.protected.submenus.map((submenu) => (
                    <Route
                        path={submenu.path}
                        key={submenu.key}
                        element={submenu.element ? <submenu.element /> : null}
                    />
                ))}
            </Route>
            <Route
                path="/unauthorized"
                element={<Errors.PermissionNotGranted />}
            />
            <Route path="*" element={<Errors.NotFound />} />
        </RouterRoutes>
    );
};
