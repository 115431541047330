import React, { useCallback, useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useLoading } from "../../../../../shared/hooks/useLoading";
import { GlobalStyle } from "../../../../../components/page-releated/cemetery-docs/header/providers/styles";
import { ICemeteryResponse } from "../../../../../shared/providers/cemetery/entities/cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../shared/services/api/cemetery-api.service";
import { TransladoExternoBody } from "../../../../../components/page-releated/cemetery-docs/translado-externo";
import { HeaderCemeteryDocs } from "../../../../../components/page-releated/cemetery-docs/header";

export const TransladoExternoStandard: React.FC = () => {
    const [cemeteryResponse, setCemeteryResponse] =
        useState<ICemeteryResponse>();

    const { setLoading } = useLoading();

    const { subscription, deceasedsubscription } = useParams<{
        subscription: string | undefined;
        deceasedsubscription: string | undefined;
    }>();

    const fetchDocument = useCallback(async () => {
        try {
            setLoading(true);

            if (!subscription) return;

            const [response] = await Promise.all([
                CemeteryInfoApiService.getCemeteryInfoByOwner(subscription, [
                    "taxes",
                    "workers",
                    "deceased",
                    "grave",
                    "transferred",
                    "attachments",
                    "geom",
                ]),
            ]);

            setCemeteryResponse(response.data[0]);
        } finally {
            setLoading(false);
        }
    }, [subscription, setLoading]);

    useEffect(() => {
        fetchDocument();
    }, [fetchDocument]);

    return (
        <Container>
            <GlobalStyle />
            <HeaderCemeteryDocs />
            {cemeteryResponse && (
                <div className="mr-14 ml-5">
                    <TransladoExternoBody
                        cemeteryInfo={cemeteryResponse}
                        deceasedSubscription={deceasedsubscription}
                    />
                </div>
            )}
        </Container>
    );
};
