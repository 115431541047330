import { ICemeteryDeceasedResponse } from "../../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { Input } from "../../../../../../global";
import { FuneralComponent } from "./funeral";
import { RegisteryComponent } from "./registery";

interface Props {
    deceasedInfo: Partial<ICemeteryDeceasedResponse>;
}
export const SepultamentoEditComponent: React.FC<Props> = ({
    deceasedInfo,
}) => {
    return (
        <div>
            <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                <Input
                    name="nameForm"
                    label="NOME"
                    defaultValue={deceasedInfo.nome}
                />
                <Input
                    name="genderForm"
                    label="SEXO"
                    defaultValue={deceasedInfo.sexo}
                />

                <Input
                    name="birthDateForm"
                    label="NASCIMENTO"
                    defaultValue={deceasedInfo.data_nascimento}
                />

                <Input
                    name="deathDateForm"
                    label="FALECIMENTO"
                    defaultValue={deceasedInfo.data_falecimento}
                />

                <Input
                    name="burialDateForm"
                    label="SEPULTAMENTO"
                    defaultValue={deceasedInfo.data_sepultamento}
                />

                <Input
                    name="exhumationDateForm"
                    label="DATA DE EXUMAÇÃO"
                    defaultValue={deceasedInfo.data_exumacao}
                />

                <Input
                    name="motherNameForm"
                    label="NOME DA MÃE"
                    defaultValue={deceasedInfo.nome_mae}
                />
                <Input
                    name="fatherNameForm"
                    label="NOME DO PAI"
                    defaultValue={deceasedInfo.nome_pai}
                />
                <Input
                    name="declarationForm"
                    label="NÚMERO DA DECLARAÇÃO DE ÓBITO"
                    defaultValue={deceasedInfo.declaracao}
                />
                <Input
                    name="atestadoForm"
                    label="NÚMERO DA CERTIDÃO DE ÓBITO"
                    defaultValue={deceasedInfo.atestado}
                />
                <RegisteryComponent
                    defaultValue={deceasedInfo.cartorio}
                    defaultLabel={deceasedInfo.cartorio}
                />

                <FuneralComponent
                    defaultValue={deceasedInfo.funeraria}
                    defaultLabel={deceasedInfo.funeraria}
                />
                <Input
                    name="bookForm"
                    label="LIVRO"
                    defaultValue={deceasedInfo.livro}
                />
                <Input
                    name="paperForm"
                    label="FOLHA"
                    defaultValue={deceasedInfo.folha}
                />
                <Input
                    name="subscriptionForm"
                    label="INSCRIÇÃO"
                    defaultValue={deceasedInfo.inscricao}
                    disabled
                    hidden
                />
                <Input
                    name="controlForm"
                    label="CONTROLE"
                    defaultValue={deceasedInfo.controle}
                    disabled
                    hidden
                />
            </div>
        </div>
    );
};
