import styled from "styled-components";

export const Container = styled.div`
    width: 750px;
    height: 550px;
    border: 2px solid black;
`;

export const MapContainer = styled.div`
    width: 100%;
    height: 100%;
`;
