import React from "react";
import environments from "../../../../environments";
import { TransladoExternoStandard } from "./providers/standard";

export const TransladoExterno: React.FC = () => {
    switch (environments.client.name) {
        default:
            return <TransladoExternoStandard />;
    }
};
