/* eslint-disable import/no-duplicates */
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import React from "react";

import { ICemeteryResponse } from "../../../../shared/providers/cemetery/entities/cemetery.interface";
import { ICemeteryTransferredResponse } from "../../../../shared/providers/cemetery/entities/transferred.interface";
import environments from "../../../../environments";

export interface TransladoInternoProps {
    cemeteryInfo: ICemeteryResponse;
    deceasedSubscription: string | undefined;
}

export const TransladoInternoBody: React.FC<TransladoInternoProps> = ({
    cemeteryInfo,
    deceasedSubscription,
}) => {
    let transferred = {} as ICemeteryTransferredResponse | undefined;
    let formattedData = "";
    if (cemeteryInfo && cemeteryInfo.transferred) {
        transferred = cemeteryInfo.transferred.find(
            (d) =>
                d.inscricao_falecido === deceasedSubscription &&
                !d.foi_revertido
        );
        if (transferred?.data_atualizacao) {
            formattedData = format(
                new Date(transferred?.data_atualizacao),
                "dd 'de' MMMM 'de' yyyy",
                {
                    locale: ptBR,
                }
            );
        }
    }

    // const withinValidDate = (data_cadastramento: string) => {
    //     const start = new Date(data_cadastramento);
    //     const now = new Date();

    //     const diffInDays = Math.floor(
    //         (Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) -
    //             Date.UTC(
    //                 start.getFullYear(),
    //                 start.getMonth(),
    //                 start.getDate()
    //             )) /
    //             (1000 * 60 * 60 * 24)
    //     );

    //     if (diffInDays < 20) {
    //         return true;
    //     }
    //     return false;
    // };

    return (
        <>
            <div className="w-full flex justify-center ">
                <h4 className="font-bold">AUTORIZAÇÃO DE TRANSLADO</h4>
            </div>
            <br />
            <div>
                <p>
                    Conforme solicitação de ,{" "}
                    <strong>
                        {cemeteryInfo.propnome}, {cemeteryInfo.nacionalidade},{" "}
                        {cemeteryInfo.estado_civil},
                    </strong>{" "}
                    inscrito(a) no CPF sob o nº{" "}
                    <strong>{cemeteryInfo.propdocumento}</strong> e portador(a)
                    do RG <strong>{cemeteryInfo.proprg}</strong> , domiciliado à
                    rua:
                    <strong>
                        {" "}
                        {cemeteryInfo.logradouronome} -{" "}
                        {cemeteryInfo.logradouronumero} -{" "}
                        {cemeteryInfo.bairronome} - {cemeteryInfo.cidade} -{" "}
                        {cemeteryInfo.estado}.
                    </strong>
                </p>
                <p>
                    Autorizamos <strong>A EXUMAÇÃO</strong> dos restos mortais
                    de:
                </p>
                {transferred && (
                    <>
                        <p>
                            <strong>{transferred.nome}</strong>, sepultado(a) na
                            Quadra: <strong>{cemeteryInfo.geom?.quadra}</strong>{" "}
                            - Lote: <strong>{cemeteryInfo.geom?.lote}</strong>,
                            no {environments.webgeo.cemiterio?.nome} de
                            {environments.webgeo.cemiterio?.municipio} - PR.
                            <br />
                            Os restos mortais serão inumados nas dependências do
                            {environments.webgeo.cemiterio?.nome} na Quadra:{" "}
                            <strong>{transferred.quadra}</strong> - Lote:{" "}
                            <strong>{transferred.lote}</strong>.
                            <br />
                            Sendo responsável pelo translado somente o
                            solicitante, vedado a terceiros, caso haja algum
                            impedimento e o mesmo comunicara a Administração,
                            para se concretize o presente ato, será obrigatório
                            a apresentação desta autorização, que deverá estar
                            acompanhada do comprovante de pagamento da taxa de
                            sepultamento.
                        </p>
                        <p>
                            Está autorização é válida por 30 dias, após sua
                            emissão.
                        </p>
                    </>
                )}
            </div>

            <div className="w-full flex justify-end">
                <h5 className="font-bold">
                    {environments.webgeo.cemiterio?.municipio}, {formattedData}.
                </h5>
            </div>
            <br />
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <strong>{cemeteryInfo.propnome}</strong>
            </div>
            <div className="w-full flex justify-center">
                CPF:&nbsp;<strong>{cemeteryInfo.propdocumento}</strong>
            </div>
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <p className="font-bold">
                    {environments.webgeo.cemiterio?.divisao}
                </p>
            </div>
        </>
    );
};
