import * as Yup from "yup";
import { ICemeteryGrave } from "../../../../../../../shared/providers/cemetery/entities/grave-cemetery.interface";

const patternTwoDigisAfterComma = /^(0|0?[1-9]\d*),\d\d$/;

const schema = Yup.object().shape({
    situacao: Yup.string().required("Campo SITUAÇÃO é obrigatório"),
    ocupacao: Yup.string().required("Campo OCUPAÇÃO é obrigatório"),
    especie: Yup.string().required("Campo ESPÉCIE é obrigatório"),
    largura: Yup.string().test(
        "is-decimal",
        "O valor da LARGURA deve ter 2 casas decimais. Exemplo (20,00)",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (val: any) => {
            if (val !== undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    ),
    comprimento: Yup.string().test(
        "is-decimal",
        "O valor do COMPRIMENTO deve ter 2 casas decimais. Exemplo (10,00)",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (val: any) => {
            if (val !== undefined) {
                return patternTwoDigisAfterComma.test(val);
            }
            return true;
        }
    ),
    inscricao: Yup.string().required(),
});

export const graveEditValidator = async (payload: ICemeteryGrave) => {
    await schema.validate(payload, {
        abortEarly: false,
    });
};
