import { semvApi } from "../axios/apis.service";

import { IPaginateApiResponse } from "../../interfaces/IPaginate";
import { IItbi } from "../../providers/itbi/interfaces";

export const ItbiApiService = {
    async getItbi(register: string): Promise<IPaginateApiResponse<IItbi[]>> {
        const { data } = await semvApi.get<IPaginateApiResponse<IItbi[]>>(
            `/itbi/${register}`
        );
        return data;
    },
};
