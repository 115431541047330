import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsArrowLeftSquare, BsPencilSquare } from "react-icons/bs";

import { FaImages, FaListUl } from "react-icons/fa";
import { FeaturesEnum } from "../../../../../../shared/enums/features.enum";
import { useAuth } from "../../../../../../shared/hooks/useAuth";
import { ICemeteryAttachment } from "../../../../../../shared/providers/cemetery/entities/attachments.interface";
import { FileList, Tooltip as TooltipGlobal } from "../../../../../global";
import { FileListProps } from "../../../../../global/file-list";
import { EditAttachment } from "./edit";

interface AttachmentsProps {
    doAfterSubmit(subscription: string): void;
    infoLayer?: Record<string, string>;
    info?: Partial<ICemeteryAttachment>;
}

export const AttachmentsCemetery: React.FC<AttachmentsProps> = ({
    doAfterSubmit,
    infoLayer,
    info,
}) => {
    const { user } = useAuth();
    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [viewType, setViewType] =
        useState<FileListProps["viewType"]>("images");

    const toggleViewType = () => {
        setViewType(viewType === "images" ? "list" : "images");
    };

    const handleEnableEdit = () => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
    };
    const handleEnableEditSubmit = (subscription: string) => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
        doAfterSubmit(subscription);
    };
    return (
        <>
            <h4>Anexos Cemitério</h4>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{enableEdit ? "VOLTAR" : "EDITAR"}</Tooltip>}
            >
                <div className="mb-3 flex space-x-2 justify-center">
                    {user.authorizationsByFeatureName[
                        FeaturesEnum.CEMETERY_ATTACHMENTS
                    ]?.canUpdate && (
                        <button type="button" onClick={handleEnableEdit}>
                            {enableEdit ? (
                                <BsArrowLeftSquare color="teal" size={20} />
                            ) : (
                                <BsPencilSquare color="teal" size={20} />
                            )}
                        </button>
                    )}
                </div>
            </OverlayTrigger>
            {enableEdit && (
                <EditAttachment
                    layerInfo={infoLayer}
                    info={info}
                    doAfterSubmit={handleEnableEditSubmit}
                />
            )}
            {!info && <div>Nenhum arquivo para exibir</div>}

            {!enableEdit && info?.anexos && (
                <div className="flex flex-1">
                    <TooltipGlobal
                        text={`Mudar visualização para ${
                            viewType === "images" ? "lista" : "imagens"
                        }`}
                    >
                        <button
                            type="button"
                            onClick={toggleViewType}
                            className="text-teal-700 mr-4"
                        >
                            {viewType === "images" ? (
                                <FaListUl size={20} />
                            ) : (
                                <FaImages size={20} />
                            )}
                        </button>
                    </TooltipGlobal>
                    <div className="block">
                        <FileList files={info?.anexos} viewType={viewType} />
                    </div>
                </div>
            )}
        </>
    );
};
