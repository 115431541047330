import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../shared/hooks/useAuth";
import { GrpLayout } from "../layout/grp";
import { FeaturesEnum } from "../shared/enums/features.enum";

interface ProtectedRoutesProps {
    restricted?: boolean;
    children?: React.ReactElement;
}

export const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({
    restricted,
    children,
}) => {
    const { signed, user } = useAuth();

    if (restricted) {
        if (
            !signed ||
            !user.authorizationsByFeatureName[FeaturesEnum.GRP_ACCESS]?.canRead
        )
            return <Navigate to="/grp/login" />;

        return (
            <GrpLayout>
                <Outlet />
            </GrpLayout>
        );
    }

    if (!signed) return <Navigate to="/" />;

    return children || null;
};
