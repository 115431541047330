import React from "react";
import { Row } from "react-bootstrap";
import { IItbi } from "../../../../../shared/providers/itbi/interfaces";

interface Props {
    data?: IItbi[];
}

export const ItbiEdit: React.FC<Props> = ({ data }) => {
    if (!data) {
        return <h4>Não encontramos nenhuma informação para este lote</h4>;
    }

    return (
        <Row>
            <h4>Adicionar 2024</h4>
            <h4>Editar 2024</h4>
        </Row>
    );
};
