import { semvApi } from "../axios/apis.service";
import {
    ICemeteryOwner,
    ICemeteryOwnerResponse,
} from "../../providers/cemetery/entities/owner-cemetery.interface";
import { IPaginateApiResponse } from "../../interfaces/IPaginate";
import { INationalities } from "../../../components/page-releated/home/cemetery/tabs/owner/components/IInterfaces";
import {
    ICemeteryTaxes,
    ICemeteryTaxesResponse,
} from "../../providers/cemetery/entities/taxes-cemetery.interface";
import { ICemeteryResponse } from "../../providers/cemetery/entities/cemetery.interface";
import {
    ICemeteryCompanies,
    ICemeteryCompaniesResponse,
    ICemeteryControlList,
    ICemeteryUtilsList,
    ICemeteryWorkers,
    ICemeteryWorkersResponse,
} from "../../providers/cemetery/entities/workers-cemetery.interface";
import {
    ICemeteryDeceased,
    ICemeteryDeceasedResponse,
} from "../../providers/cemetery/entities/deceased-cemetery.interface";
import {
    ICemeteryGrave,
    ICemeteryGraveResponse,
} from "../../providers/cemetery/entities/grave-cemetery.interface";
import {
    ICemeteryTransferredIntern,
    ICemeteryTransferredResponse,
} from "../../providers/cemetery/entities/transferred.interface";
import { ICemeteryAttachment } from "../../providers/cemetery/entities/attachments.interface";
import {
    ICemeteryFunerary,
    ICemeteryFuneraryResponse,
} from "../../providers/cemetery/entities/funerary.interface";
import {
    ICemeteryRegistery,
    ICemeteryRegisteryResponse,
} from "../../providers/cemetery/entities/registery.interface";

export const CemeteryInfoApiService = {
    async createOwner(
        payload: ICemeteryOwner
    ): Promise<ICemeteryOwnerResponse> {
        const { data: owner } = await semvApi.post<ICemeteryOwnerResponse>(
            `/cemetery/owner`,
            payload
        );
        return owner;
    },
    async createTaxes(
        payload: ICemeteryTaxes
    ): Promise<ICemeteryTaxesResponse> {
        const { data: taxes } = await semvApi.post<ICemeteryTaxesResponse>(
            `/cemetery/taxes`,
            payload
        );
        return taxes;
    },
    async createWorker(
        payload: ICemeteryWorkers
    ): Promise<ICemeteryWorkersResponse> {
        const { data: worker } = await semvApi.post<ICemeteryWorkersResponse>(
            `/cemetery/workers`,
            payload
        );
        return worker;
    },
    async createWorkerCompanies(
        payload: ICemeteryCompanies
    ): Promise<ICemeteryCompaniesResponse> {
        const { data: company } =
            await semvApi.post<ICemeteryCompaniesResponse>(
                `/cemetery/workers-companies`,
                payload
            );
        return company;
    },
    async createDeceased(
        payload: ICemeteryDeceased
    ): Promise<ICemeteryDeceasedResponse> {
        const { data: deceased } =
            await semvApi.post<ICemeteryDeceasedResponse>(
                `/cemetery/deceased`,
                payload
            );
        return deceased;
    },
    async createGrave(
        payload: ICemeteryGrave
    ): Promise<ICemeteryGraveResponse> {
        const { data: grave } = await semvApi.post<ICemeteryGraveResponse>(
            `/cemetery/grave`,
            payload
        );
        return grave;
    },
    async createTransferred(
        payload: ICemeteryTransferredIntern
    ): Promise<ICemeteryTransferredResponse> {
        const { data: transferred } =
            await semvApi.post<ICemeteryTransferredResponse>(
                `/cemetery/transferred`,
                payload
            );
        return transferred;
    },
    async createFuneral(
        payload: ICemeteryFunerary
    ): Promise<ICemeteryFuneraryResponse> {
        const { data: funerary } =
            await semvApi.post<ICemeteryFuneraryResponse>(
                `/cemetery/funeral`,
                payload
            );
        return funerary;
    },
    async createRegistery(
        payload: ICemeteryRegistery
    ): Promise<ICemeteryRegisteryResponse> {
        const { data: funerary } =
            await semvApi.post<ICemeteryRegisteryResponse>(
                `/cemetery/registery`,
                payload
            );
        return funerary;
    },
    async getCemeteryInfoByOwner(
        subscription: string,
        relations?: string[]
    ): Promise<IPaginateApiResponse<ICemeteryResponse[]>> {
        const { data } = await semvApi.get<
            IPaginateApiResponse<ICemeteryResponse[]>
        >(`/cemetery/${subscription}/owner?relations=${relations?.join(",")}`);
        return data;
    },
    async getCemeteryInfo(
        subscription: string,
        relations?: string[]
    ): Promise<IPaginateApiResponse<ICemeteryResponse[]>> {
        const { data } = await semvApi.get<
            IPaginateApiResponse<ICemeteryResponse[]>
        >(`/cemetery/${subscription}/grave?relations=${relations?.join(",")}`);

        return data;
    },
    async listCemeteryInfoPropertiesByGrave(
        query: string,
        relations?: string[]
    ): Promise<IPaginateApiResponse<ICemeteryResponse[]>> {
        const { data: properties } = await semvApi.get(
            `/cemetery/grave?${query}&relations=${relations?.join(",")}`
        );
        return properties;
    },
    async listCemeteryInfoProperties(
        query: string,
        relations?: string[]
    ): Promise<IPaginateApiResponse<ICemeteryResponse[]>> {
        const { data: properties } = await semvApi.get(
            `/cemetery/owners?${query}&relations=${relations?.join(",")}`
        );
        return properties;
    },
    async deceasedList(
        query: string
    ): Promise<IPaginateApiResponse<ICemeteryDeceasedResponse[]>> {
        const { data: properties } = await semvApi.get(
            `/cemetery/deceased?${query}`
        );
        return properties;
    },
    async getOwner(
        subscription: string
    ): Promise<IPaginateApiResponse<ICemeteryOwnerResponse[]>> {
        const { data } = await semvApi.get<
            IPaginateApiResponse<ICemeteryOwnerResponse[]>
        >(`/cemetery/${subscription}/owner`);
        return data;
    },
    async getDeceased(
        subscription: string
    ): Promise<IPaginateApiResponse<ICemeteryDeceasedResponse[]>> {
        const { data } = await semvApi.get<
            IPaginateApiResponse<ICemeteryDeceasedResponse[]>
        >(`/cemetery/${subscription}/deceased`);
        return data;
    },
    async getDeceasedById(id: number): Promise<ICemeteryDeceasedResponse> {
        const { data } = await semvApi.get<ICemeteryDeceasedResponse>(
            `/cemetery/byid/${id}/deceased`
        );
        return data;
    },
    async getCompanyById(id: number): Promise<ICemeteryCompaniesResponse> {
        const { data } = await semvApi.get<ICemeteryCompaniesResponse>(
            `/cemetery/${id}/workers-companies`
        );
        return data;
    },
    async getTransferred(id: number): Promise<ICemeteryTransferredResponse> {
        const { data } = await semvApi.get<ICemeteryTransferredResponse>(
            `/cemetery/${id}/transferred`
        );
        return data;
    },
    async getFunerals(): Promise<
        IPaginateApiResponse<ICemeteryFuneraryResponse[]>
    > {
        const { data } = await semvApi.get<
            IPaginateApiResponse<ICemeteryFuneraryResponse[]>
        >(`/cemetery/funeral`);
        return data;
    },
    async getRegistery(): Promise<
        IPaginateApiResponse<ICemeteryRegisteryResponse[]>
    > {
        const { data } = await semvApi.get<
            IPaginateApiResponse<ICemeteryRegisteryResponse[]>
        >(`/cemetery/registery`);
        return data;
    },
    async updateOwner(
        id: number,
        payload: ICemeteryOwner
    ): Promise<ICemeteryOwnerResponse> {
        const { data } = await semvApi.patch<ICemeteryOwnerResponse>(
            `/cemetery/${id}/owner`,
            payload
        );
        return data;
    },
    async updateTaxes(
        id: number,
        payload: ICemeteryTaxes
    ): Promise<ICemeteryTaxesResponse> {
        const { data } = await semvApi.patch<ICemeteryTaxesResponse>(
            `/cemetery/${id}/taxes`,
            payload
        );
        return data;
    },
    async updateWorkers(
        id: number,
        payload: ICemeteryWorkers
    ): Promise<ICemeteryWorkersResponse> {
        const { data } = await semvApi.patch<ICemeteryWorkersResponse>(
            `/cemetery/${id}/workers`,
            payload
        );
        return data;
    },
    async updateDeceased(
        id: number,
        payload: Partial<ICemeteryDeceased>
    ): Promise<ICemeteryDeceasedResponse> {
        const { data } = await semvApi.patch<ICemeteryDeceasedResponse>(
            `/cemetery/${id}/deceased`,
            payload
        );
        return data;
    },
    async updateTransferred(
        id: number,
        payload: Partial<ICemeteryTransferredResponse>
    ): Promise<ICemeteryTransferredResponse> {
        const { data } = await semvApi.patch<ICemeteryTransferredResponse>(
            `/cemetery/${id}/transferred`,
            payload
        );
        return data;
    },
    async updateGrave(
        id: number,
        payload: ICemeteryGrave
    ): Promise<ICemeteryGraveResponse> {
        const { data } = await semvApi.patch<ICemeteryGraveResponse>(
            `/cemetery/${id}/grave`,
            payload
        );
        return data;
    },
    async updateRegistery(
        id: number,
        payload: ICemeteryRegistery
    ): Promise<ICemeteryRegisteryResponse> {
        const { data } = await semvApi.patch<ICemeteryRegisteryResponse>(
            `/cemetery/${id}/registery`,
            payload
        );
        return data;
    },
    async getNationalities(): Promise<IPaginateApiResponse<INationalities[]>> {
        const { data } = await semvApi.get<
            IPaginateApiResponse<INationalities[]>
        >(`/utils/nationalities`);
        return data;
    },
    async getCompanies(): Promise<
        IPaginateApiResponse<ICemeteryCompaniesResponse[]>
    > {
        const { data } = await semvApi.get<
            IPaginateApiResponse<ICemeteryCompaniesResponse[]>
        >(`/utils/companies`);
        return data;
    },
    async getSequenceDeceased(
        subscription: string
    ): Promise<{ sequenceNumber: string }> {
        const { data } = await semvApi.get<{ sequenceNumber: string }>(
            `/utils/${subscription}/deceased-sequence`
        );
        return data;
    },
    async getControlList(): Promise<
        IPaginateApiResponse<ICemeteryControlList[]>
    > {
        const { data } = await semvApi.get<
            IPaginateApiResponse<ICemeteryControlList[]>
        >(`/utils/control-list?select=inscricao,controle,lote,quadra`);
        return data;
    },
    async getRegisteryList(): Promise<ICemeteryUtilsList[]> {
        const { data } = await semvApi.get<ICemeteryUtilsList[]>(
            `/utils/registery-list`
        );
        return data;
    },
    async getFuneralList(): Promise<ICemeteryUtilsList[]> {
        const { data } = await semvApi.get<ICemeteryUtilsList[]>(
            `/utils/funeral-list`
        );
        return data;
    },
    async getCountryList(): Promise<ICemeteryUtilsList[]> {
        const { data } = await semvApi.get<ICemeteryUtilsList[]>(
            `/utils/country-list`
        );
        return data;
    },
    // async getCemeteryGeom(
    //     query: string
    // ): Promise<IPaginateApiResponse<ICemeteryGeom[]>> {
    //     const { data } = await semvApi.get<
    //         IPaginateApiResponse<ICemeteryGeom[]>
    //     >(`/utils/cemetery-geom?${query}`);
    //     return data;
    // },
    async deleteOwner(id: number): Promise<number> {
        const { data: response } = await semvApi.delete(
            `/cemetery/${id}/owner`
        );
        return response;
    },
    async deleteDeceased(id: number): Promise<number> {
        const { data: response } = await semvApi.delete(
            `/cemetery/${id}/deceased`
        );
        return response;
    },
    async deleteGrave(id: number): Promise<number> {
        const { data: response } = await semvApi.delete(
            `/cemetery/${id}/grave`
        );
        return response;
    },
    async deleteWorker(id: number): Promise<number> {
        const { data: response } = await semvApi.delete(
            `/cemetery/${id}/workers`
        );
        return response;
    },
    async deleteTaxes(id: number): Promise<number> {
        const { data: response } = await semvApi.delete(
            `/cemetery/${id}/taxes`
        );
        return response;
    },
    async deleteTransferred(id: number): Promise<number> {
        const { data: response } = await semvApi.delete(
            `/cemetery/${id}/transferred`
        );
        return response;
    },
    async deleteFuneral(id: number): Promise<number> {
        const { data: response } = await semvApi.delete(
            `/cemetery/${id}/funeral`
        );
        return response;
    },
    async deleteRegistery(id: number): Promise<number> {
        const { data: response } = await semvApi.delete(
            `/cemetery/${id}/registery`
        );
        return response;
    },
    async updloadCemeteryFiles(
        subscription: string,
        payload: FormData
    ): Promise<{ fileName: string; destination: string }[]> {
        const { data } = await semvApi.post(
            `/cemetery/attachment/${subscription}/upload`,
            payload,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );

        return data.data;
    },
    async uploadFiles(
        files: File[],
        subscription: string
    ): Promise<{ fileName: string; destination: string }[]> {
        const filesForm = new FormData();

        files.forEach((file) => filesForm.append("files", file));

        const uploadedFiles = await this.updloadCemeteryFiles(
            subscription,
            filesForm
        );

        return uploadedFiles;
    },
    async updateAttachmentNames(
        subscription: string,
        payload: Partial<ICemeteryAttachment>
    ): Promise<ICemeteryAttachment> {
        const { data } = await semvApi.patch<ICemeteryAttachment>(
            `/cemetery/attachment/${subscription}/update`,
            payload
        );
        return data;
    },
    async createAttachmentNames(
        subscription: string,
        payload: Partial<ICemeteryAttachment>
    ): Promise<ICemeteryAttachment> {
        const { data } = await semvApi.post<ICemeteryAttachment>(
            `/cemetery/attachment/${subscription}/add-new`,
            payload
        );
        return data;
    },
};
