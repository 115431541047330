import React from "react";

import { WebgeoLogo } from "../../../../shared/assets/logos";
import { Container } from "./style";

export const HeaderPalmeira: React.FC = () => {
    return (
        <Container>
            {/* <LeftHeader>
                <div style={{ width: "100px" }}>
                    <img src={ClientLogo} alt="logo cidade" />
                </div>
            </LeftHeader>
            <div style={{ width: "200px" }}>
                <img
                    src="https://d1cge5fflrvk0g.cloudfront.net/palmeira/logos/secretaria_urbanismo.JPG"
                    alt="Brasão de Palmeira com a escrita indicando Município de PALMEIRA Secretaria de Urbanismo"
                />
            </div> */}
            <section style={{ width: "200px" }}>
                <img
                    src="https://d1cge5fflrvk0g.cloudfront.net/palmeira/logos/prefeitura_municipal_small.png"
                    alt="logo cidade"
                />
            </section>
            <section style={{ width: "200px" }}>
                <img
                    src="https://d1cge5fflrvk0g.cloudfront.net/palmeira/logos/secretaria_urbanismo.JPG"
                    alt="Brasão de Palmeira com a escrita indicando Município de PALMEIRA Secretaria de Urbanismo"
                />
            </section>
            <section style={{ width: "200px" }}>
                <img src={WebgeoLogo} alt="logo webgeo" />
            </section>
        </Container>
    );
};
