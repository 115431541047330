export enum FeaturesEnum {
    USERS = "users",
    USERS_MANAGER = "users_manager",

    ROLES = "roles",
    SECRETARIES = "secretaries",

    GRP_ACCESS = "grp_access",

    PROJECTS = "projects",

    LIST_GOALS = "lista_metas",
    STEPS = "etapas",

    PERMISSIONS = "permissions",

    PROPERTIES = "properties",
    PROPERTIES_VALUES = "properties_values",
    PROPERTIES_TESTADAS = "properties_testadas",
    CONFRONTANTE = "confrontante",
    INFO_CONFRONTANTE = "info_confrontante",
    IMOVEL_CONFRONTANTE = "imovel_confrontante",

    BCI = "bci",
    IPTU = "iptu",
    CONSULTA_PREVIA = "consulta_previa",

    LAYERS = "layers",
    LAYERS_MANAGER = "layers_manager",
    LAYERS_GROUPS = "layers_groups",
    LAYERS_STYLES = "layers_styles",

    LOGS = "logs",

    CEMETERY_OWNER = "cemetery_owner",
    NATIONALITIES = "cemiterio.nacionalidade",
    CEMETERY_TAXES = "cemetery_taxes",
    CEMETERY_WORKERS = "cemetery_workers",
    CEMETERY_DECEASED = "cemetery_deceased",
    CEMETERY_GRAVE = "cemetery_grave",
    CEMETERY_WORKERS_COMPANIES = "cemetery_workers_companies",
    CEMETERY_PROPERTIES = "propriedades_cemiterio",
    CEMETERY_ATTACHMENTS = "cemetery_attachments",
    CEMETERY_TRANSFERRED = "cemetery_transferred",

    TRANSLADO_INTERNO = "translado_interno",
    TRANSLADO_EXTERNO = "translado_externo",

    BCM = "bcm",
    MODELO_POSSE = "modelo_posse",
    ABERTURA_TUMULO = "abertura_tumulo",
    AUTORIZACAO_REFORMA = "autorizacao_reforma",
    CEMETERY_SEARCH = "cemetery_search",
    CEMETERY_SUB_FIELDS = "cemetery_sub_fields",
}
