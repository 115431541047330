import React, { useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { FormCheck } from "react-bootstrap";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { MainButton } from "../../../../../global";
import { Input } from "../../../../../global/input/index";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import {
    ICemeteryDeceased,
    ICemeteryDeceasedResponse,
    ICemeteryForm,
} from "../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { deceasedValidator } from "./components/validator";
import { yupValidationErrorByField } from "../../../../../../shared/utils/yup-error-by-field";
import { deceasedTransferredValidator } from "./components/validatorTransferred";
import { Cities } from "../owner/components/cities";
import { SepultamentoAddComponent } from "./components/sepultamento";

const labelStyle = { fontSize: "15px", color: "#234e52" };

interface AddFormProps {
    doAfterSubmit(subscription: string): void;
    subscription?: string | undefined;
    control?: string | undefined;
    deceasedInfo?: Partial<ICemeteryDeceasedResponse>;
}
export const AddDeceased: React.FC<AddFormProps> = ({
    doAfterSubmit,
    subscription,
    control,
    deceasedInfo,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [sequenceNumber, setSequenceNumber] = useState<string>("");
    const [registerType, setRegisterType] = useState<
        "SEPULTAMENTO" | "TRANSLADO RECEBIMENTO"
    >("SEPULTAMENTO");

    const handleSubmitSepultamento = async ({
        nameForm,
        genderForm,
        birthDateForm,
        deathDateForm,
        burialDateForm,
        motherNameForm,
        fatherNameForm,
        declarationForm,
        atestadoForm,
        cartorioForm,
        funerariaForm,
        bookForm,
        paperForm,
        subscriptionForm,
        controlForm,
        exhumationDateForm,
        inscricaoFalecidoForm,
    }: ICemeteryForm) => {
        const payload: ICemeteryDeceased = {
            nome: nameForm,
            sexo: genderForm,
            data_nascimento: birthDateForm,
            data_falecimento: deathDateForm,
            data_sepultamento: burialDateForm,
            nome_mae: motherNameForm,
            nome_pai: fatherNameForm,
            declaracao: declarationForm,
            atestado: atestadoForm,
            cartorio: cartorioForm,
            funeraria: funerariaForm,
            livro: bookForm,
            folha: paperForm,
            data_exumacao: exhumationDateForm,
            inscricao: subscriptionForm,
            controle: controlForm,
            tipo_cadastramento: registerType,
            translado_cidade: "",
            translado_origem: "",
            inscricao_falecido: inscricaoFalecidoForm,
            unidade: parseInt(sequenceNumber, 10),
        };
        try {
            setLoading(true);

            await deceasedValidator(payload);

            await CemeteryInfoApiService.createDeceased(payload);

            showToast({
                type: "success",
                message: "Dados de falecido foram inseridos com sucesso",
            });

            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    const handleTransferredSubmit = async ({
        nameForm,
        genderForm,
        birthDateForm,
        deathDateForm,
        burialDateForm,
        motherNameForm,
        fatherNameForm,
        declarationForm,
        atestadoForm,
        cartorioForm,
        funerariaForm,
        bookForm,
        paperForm,
        subscriptionForm,
        controlForm,
        exhumationDateForm,
        originForm,
        cidadeForm,
        inscricaoFalecidoForm,
    }: ICemeteryForm) => {
        const payload: ICemeteryDeceased = {
            nome: nameForm,
            sexo: genderForm,
            data_nascimento: birthDateForm,
            data_falecimento: deathDateForm,
            data_sepultamento: burialDateForm,
            nome_mae: motherNameForm,
            nome_pai: fatherNameForm,
            declaracao: declarationForm,
            atestado: atestadoForm,
            cartorio: cartorioForm,
            funeraria: funerariaForm,
            livro: bookForm,
            folha: paperForm,
            data_exumacao: exhumationDateForm,
            inscricao: subscriptionForm,
            controle: controlForm,
            tipo_cadastramento: registerType,
            translado_cidade: cidadeForm || "",
            translado_origem: originForm || "",
            inscricao_falecido: inscricaoFalecidoForm,
            unidade: parseInt(sequenceNumber, 10),
        };

        try {
            setLoading(true);
            await deceasedTransferredValidator(payload);

            await CemeteryInfoApiService.createDeceased(payload);

            showToast({
                type: "success",
                message: "Dados de falecido foram inseridos com sucesso",
            });

            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    const handleRelatedByChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRegisterType(
            event.target.value as "SEPULTAMENTO" | "TRANSLADO RECEBIMENTO"
        );
    };

    const getSequenceDeceased = async (subscription_: string) => {
        try {
            setLoading(true);
            const sequenceNmber =
                await CemeteryInfoApiService.getSequenceDeceased(subscription_);

            setSequenceNumber(sequenceNmber.sequenceNumber);
        } catch (err) {
            ApiErrorHandler(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (subscription) {
            getSequenceDeceased(subscription);
        }
    }, [subscription]);

    return (
        <div>
            {!loading && (
                <div className="flex space-x-2 ml-2">
                    <p style={labelStyle}>Tipo do Cadastro:</p>
                    <FormCheck
                        type="radio"
                        name="serviceTypeSepultamento"
                        label="SEPULTAMAMENTO"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="SEPULTAMENTO"
                        checked={registerType === "SEPULTAMENTO"}
                    />
                    <FormCheck
                        type="radio"
                        name="serviceTypeTranslado"
                        label="TRANSLADO RECEBIMENTO"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="TRANSLADO RECEBIMENTO"
                        checked={registerType === "TRANSLADO RECEBIMENTO"}
                    />
                </div>
            )}
            {!loading && registerType === "SEPULTAMENTO" && (
                <Form
                    onSubmit={handleSubmitSepultamento}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <>
                        <SepultamentoAddComponent
                            deceasedInfo={deceasedInfo}
                            control={control}
                            subscription={subscription}
                            sequenceNumber={sequenceNumber}
                        />
                        <div className="w-full md:w-6/6 flex">
                            <div className="md:w-2/3 h-10 mt-2">
                                <MainButton type="submit">SALVAR</MainButton>
                            </div>
                        </div>
                    </>
                </Form>
            )}
            {!loading && registerType === "TRANSLADO RECEBIMENTO" && (
                <Form
                    onSubmit={handleTransferredSubmit}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <SepultamentoAddComponent
                        deceasedInfo={deceasedInfo}
                        control={control}
                        subscription={subscription}
                        sequenceNumber={sequenceNumber}
                    />
                    <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                        <Input name="originForm" label="ORIGEM" />

                        <Cities city="" maxMenuHeight={50} />
                    </div>
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-2">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};
