import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { CemeteryInfoApiService } from "../../../../../../../../shared/services/api/cemetery-api.service";
import { ApiErrorHandler } from "../../../../../../../../shared/utils/errors.utils";
import { Input, MainButton } from "../../../../../../../global";
import { showToast } from "../../../../../../../global/toast";
import { Cities } from "../../../owner/components/cities";
import { States } from "../../../owner/components/states";
import {
    ICemeteryFunerary,
    ICemeteryFuneraryForm,
} from "../../../../../../../../shared/providers/cemetery/entities/funerary.interface";

interface AddProps {
    doAfterSubmit(): void;
}

export const AddRegistery: React.FC<AddProps> = ({ doAfterSubmit }) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async ({
        nomeForm,
        cidadeForm,
        estadoForm,
    }: ICemeteryFuneraryForm) => {
        const payload: ICemeteryFunerary = {
            nome: nomeForm,
            cidade: cidadeForm,
            estado: estadoForm,
        };
        try {
            setLoading(true);

            await CemeteryInfoApiService.createRegistery(payload);

            showToast({
                type: "success",
                message: "Dados do cartório foram adicionados com sucesso",
            });
            doAfterSubmit();
        } catch (err) {
            ApiErrorHandler(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-50">
            <p>Adicionar Cartório</p>
            {!loading && (
                <Form
                    onSubmit={handleSubmit}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <div className="grid gap-1 grid-cols-3 grid-rows-1">
                        <Input name="nomeForm" label="NOME" />
                        <Cities city="" />

                        <States state="" />
                    </div>
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-48">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};
