import { useState } from "react";
import {
    GrDocumentCsv,
    GrDocumentExcel,
    GrDocumentWord,
    GrDocumentPdf,
    GrDocument,
    GrDocumentImage,
} from "react-icons/gr";
import Dwg from "../../../../shared/assets/icons/dwg.png";

const Image = ({ download, thumb }: { thumb?: string; download: string }) => {
    const [hasError, setHasError] = useState(false);
    return !hasError ? (
        <img
            src={thumb}
            alt="anexo"
            className="w-12 h-12"
            onError={() => setHasError(true)}
        />
    ) : (
        <div className="flex space-x-2.5 items-center">
            <GrDocumentImage color="blue" size={32} />
            <span className="trucate text-sm">{download.split("/").pop()}</span>
        </div>
    );
};

export const FileRender = ({
    fileUrl,
    thumbnail,
}: {
    fileUrl: string;
    thumbnail?: string;
}) => {
    const extension = fileUrl.split("/").pop()?.split(".").pop()?.toLowerCase();

    const fileName = fileUrl.split("/").pop();

    switch (extension) {
        case "jpeg":
        case "pjpeg":
        case "jpg":
        case "png":
            return <Image download={fileUrl} thumb={thumbnail} />;
        case "csv":
            return (
                <div className="flex space-x-2.5 items-center">
                    <GrDocumentCsv color="#089706" size={32} />
                    <span className="truncate">{fileName}</span>
                </div>
            );
        case "xls":
        case "xlsx":
            return (
                <div className="flex space-x-2.5 items-center">
                    <GrDocumentExcel color="#089706" size={32} />
                    <span className="truncate text-sm max-w-sm">
                        {fileName}
                    </span>
                </div>
            );
        case "doc":
        case "docx":
            return (
                <div className="flex space-x-2.5 items-center">
                    <GrDocumentWord color="#55AAFF" size={32} />
                    <span className="truncate text-sm max-w-sm">
                        {fileName}
                    </span>
                </div>
            );

        case "dwg":
            return (
                <div className="flex space-x-2.5 items-center">
                    <img
                        src={Dwg}
                        alt="anexo em dwg"
                        className="w-10 h-10 -ml-1"
                    />

                    <span className="truncate text-sm max-w-sm">
                        {fileName}
                    </span>
                </div>
            );

        case "pdf":
            return (
                <div className="flex space-x-2.5 items-center">
                    <GrDocumentPdf color="#fa1d1d" size={32} />
                    <span className="truncate text-sm max-w-sm ">
                        {fileName}
                    </span>
                </div>
            );

        default:
            return (
                <div className="flex space-x-2.5 items-center">
                    <GrDocument size={32} />
                    <span className="truncate text-sm max-w-sm">
                        {fileName}
                    </span>
                </div>
            );
    }
};
