import { ICemeteryWorkersResponse } from "../../../../../../../shared/providers/cemetery/entities/workers-cemetery.interface";
import { Input, InputMask, Select } from "../../../../../../global";

interface Props {
    workersInfo?: Partial<ICemeteryWorkersResponse>;
}
export const WorkerComponent: React.FC<Props> = ({ workersInfo }) => {
    const cadastramento = new Date().toISOString();
    return (
        <div>
            <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                <Select
                    name="tiposervicoForm"
                    label="TIPO DO SERVIÇO"
                    defaultValue={{
                        label: workersInfo?.tipo_servico,
                        value: workersInfo?.tipo_servico,
                    }}
                    options={[
                        {
                            label: "CONSTRUÇÃO",
                            value: "CONSTRUÇÃO",
                        },
                        {
                            label: "MANUTENÇÃO",
                            value: "MANUTENÇÃO",
                        },
                        {
                            label: "LIMPEZA",
                            value: "LIMPEZA",
                        },
                    ]}
                />
                <Select
                    name="funcaocolaboradorForm"
                    label="FUNÇÃO DO COLABORADOR"
                    defaultValue={{
                        label: workersInfo?.funcao_colaborador,
                        value: workersInfo?.funcao_colaborador,
                    }}
                    options={[
                        {
                            label: "PEDREIRO(A)",
                            value: "PEDREIRO(A)",
                        },
                        {
                            label: "FAXINEIRO(A)",
                            value: "FAXINEIRO(A)",
                        },
                        {
                            label: "AUXILIAR GERAL",
                            value: "AUXILIAR GERAL",
                        },
                        {
                            label: "SERVENTE",
                            value: "SERVENTE",
                        },
                        {
                            label: "ELETRICISTA",
                            value: "ELETRICISTA",
                        },
                    ]}
                />
                <Input
                    name="nomecolaboradorForm"
                    label="NOME DO COLABORADOR"
                    defaultValue={workersInfo?.nome_colaborador}
                />
                <InputMask
                    name="cpfcolaboradorForm"
                    label="CPF DO COLABORADOR"
                    defaultValue={workersInfo?.cpf_colaborador}
                    mask="999.999.999-99"
                />

                <InputMask
                    name="telefonecolaboradorForm"
                    label="TELEFONE DO COLABORADOR"
                    defaultValue={workersInfo?.telefone_colaborador}
                    mask="(99)99999-9999"
                />
                <Input
                    name="emailcolaboradorForm"
                    label="EMAIL DO COLABORADOR"
                    defaultValue={workersInfo?.email_colaborador}
                />

                <Input
                    name="cadastramentoForm"
                    label="DATA DE CADASTRAMENTO"
                    defaultValue={cadastramento}
                    disabled
                    hidden
                />
            </div>
        </div>
    );
};
