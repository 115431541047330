/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { BsArrowLeftSquare } from "react-icons/bs";
import { ICemeteryWorkersResponse } from "../../../../../../shared/providers/cemetery/entities/workers-cemetery.interface";

interface ReadFormProps {
    backButton(): void;
    info?: Partial<ICemeteryWorkersResponse>;
}
export const ReadWorkers: React.FC<ReadFormProps> = ({ backButton, info }) => {
    const handleGoBack = () => {
        backButton();
    };

    return (
        <div className="bg-gray-50">
            <div className="grid grid-cols-3 grid-rows-1">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>VOLTAR</Tooltip>}
                >
                    <div className="mb-3 flex space-x-2 justify-center">
                        <button type="button" onClick={handleGoBack}>
                            <BsArrowLeftSquare color="teal" size={20} />
                        </button>
                    </div>
                </OverlayTrigger>
            </div>
            {info && info.tipo_prestador && info?.tipo_prestador === "EMPRESA" && (
                <Form.Group>
                    <Row>
                        <h4>PRESTADOR EMPRESA</h4>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                NOME DO RESPONSÁVEL
                            </Form.Label>
                            <Form.Control
                                value={info?.responsavel_empresa}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                CNPJ/CPF
                            </Form.Label>
                            <Form.Control
                                value={info?.documento_empresa}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                RAZÃO SOCIAL
                            </Form.Label>
                            <Form.Control
                                value={info?.razao_social_empresa}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                TIPO DE SERVIÇO
                            </Form.Label>
                            <Form.Control
                                value={info?.tipo_servico_empresa}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                TELEFONE DA EMPRESA
                            </Form.Label>
                            <Form.Control
                                value={info?.telefone_empresa}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                EMAIL DA EMPRESA
                            </Form.Label>
                            <Form.Control
                                value={info?.email_empresa}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                NOME DO COLABORADOR
                            </Form.Label>
                            <Form.Control
                                value={info?.nome_colaborador}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                CPF DO COLABORADOR
                            </Form.Label>
                            <Form.Control
                                value={info?.cpf_colaborador}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                TELEFONE DO COLABORADOR
                            </Form.Label>
                            <Form.Control
                                value={info?.telefone_colaborador}
                                disabled
                            />
                        </Col>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                EMAIL DO COLABORADOR
                            </Form.Label>
                            <Form.Control
                                value={info?.email_colaborador}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                FUNÇÃO DO COLABORADOR
                            </Form.Label>
                            <Form.Control
                                value={info?.funcao_colaborador}
                                disabled
                            />
                        </Col>
                    </Row>
                </Form.Group>
            )}
            {info &&
                info.tipo_prestador &&
                info?.tipo_prestador === "PROPRIETÁRIO" && (
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TIPO DO PRESTADOR
                                </Form.Label>
                                <Form.Control
                                    value={info?.tipo_prestador}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TIPO DO SERVIÇO
                                </Form.Label>
                                <Form.Control
                                    value={info?.tipo_servico}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    NOME DO COLABORADOR
                                </Form.Label>
                                <Form.Control
                                    value={info?.nome_colaborador}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    CPF DO COLABORADOR
                                </Form.Label>
                                <Form.Control
                                    value={info?.cpf_colaborador}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TELEFONE DO COLABORADOR
                                </Form.Label>
                                <Form.Control
                                    value={info?.telefone_colaborador}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    EMAIL DO COLABORADOR
                                </Form.Label>
                                <Form.Control
                                    value={info?.email_colaborador}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    FUNÇÃO DO COLABORADOR
                                </Form.Label>
                                <Form.Control
                                    value={info?.funcao_colaborador}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                )}
        </div>
    );
};
