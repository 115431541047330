import React from "react";
import environments from "../../../../environments";
import { ModeloDePosseStandard } from "./providers/standard";

export const ModeloPosse: React.FC = () => {
    switch (environments.client.name) {
        default:
            return <ModeloDePosseStandard />;
    }
};
