import { useState, useEffect, useCallback } from "react";
import { createFilter } from "react-select";
import { SelectOptions } from "../../../../../../../shared/interfaces/ISelectOptions";
import { ICemeteryUtilsList } from "../../../../../../../shared/providers/cemetery/entities/workers-cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../../../shared/services/api/cemetery-api.service";
import { ApiErrorHandler } from "../../../../../../../shared/utils/errors.utils";
import { Select } from "../../../../../../global";

interface Props {
    defaultValue?: string;
    defaultLabel?: string;
}

export const FuneralComponent: React.FC<Props> = ({
    defaultValue,
    defaultLabel,
}) => {
    const [options, setOptions] = useState<SelectOptions[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const convertOptions = async (opts: ICemeteryUtilsList[]) => {
        const selectOptions: SelectOptions[] = [];

        opts.forEach((element: ICemeteryUtilsList) => {
            const option: SelectOptions = {
                label: element.nome,
                value: element.nome,
            };
            selectOptions.push(option);
        });

        return selectOptions;
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const controlListResponse =
                await CemeteryInfoApiService.getFuneralList();

            const op = await convertOptions(controlListResponse);
            setOptions(op);
        } catch (error) {
            ApiErrorHandler(error, "warn");
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            {!loading && (
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                        ignoreCase: true,
                    })}
                    name="funerariaForm"
                    label="FUNERÁRIA"
                    defaultValue={{ value: defaultValue, label: defaultLabel }}
                    options={options}
                    isClearable
                    placeholder="Selecione uma funerária..."
                    noOptionsMessage={() =>
                        "Cadastre uma nova funerária na aba de CADASTRAMENTOS"
                    }
                />
            )}
        </div>
    );
};
