import React from "react";
import { Background } from "./styles";

interface Props {
    show: boolean;
    handleConfirm(): void;
    handleCancel(): void;
    phrase: string;
}

export const Popup: React.FC<Props> = ({
    show,
    handleConfirm,
    handleCancel,
    phrase,
}) => {
    return (
        <Background show={show}>
            <div className="grid grid-cols-1 gap-4 justify-items-center content-center">
                <p className="col-span-2">{phrase}</p>
                <button
                    type="button"
                    className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                    onClick={handleCancel}
                >
                    NÃO
                </button>
                <button
                    type="button"
                    className="focus:outline-none text-white bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800"
                    onClick={handleConfirm}
                >
                    SIM
                </button>
            </div>
        </Background>
    );
};
