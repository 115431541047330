import React from "react";
import { Row } from "react-bootstrap";
import { IProperty } from "../../../../shared/interfaces/IProperties";

interface Props {
    propertyInfo?: IProperty;
}

export const PalmeiraPrincipaisDados: React.FC<Props> = ({ propertyInfo }) => {
    return (
        <>
            <p className="font-semibold">
                DADOS PRINCIPAIS SOBRE O LOTE ► SMDU | SMGPF
            </p>

            <Row>
                <table className="table">
                    <tbody>
                        <tr
                            className="border-0"
                            style={{ background: "#D0CECE" }}
                        >
                            <td className="border-r-2 border-white">
                                CAD IMOBILIÁRIO
                            </td>
                            <td className="border-r-2 border-white">
                                ÁREA DO LOTE
                            </td>
                            <td className="border-r-2 border-white">
                                ÁREA EDIFICADA
                            </td>
                            <td className="border-r-2 border-white">
                                Nº PVTOS
                            </td>
                            <td className="border-r-2 border-white">
                                TX OCUPAÇ
                            </td>
                            <td className="border-r-2 border-white">
                                COEFIC. APROV
                            </td>
                            <td style={{ color: "#ff0000" }}>PERMEABIL</td>
                        </tr>
                        <tr style={{ background: "#D0CECE" }}>
                            <td className="border-r-2 border-white">(UN)</td>
                            <td className="border-r-2 border-white">(m²)</td>
                            <td className="border-r-2 border-white">(m²)</td>
                            <td className="border-r-2 border-white">UN</td>
                            <td className="border-r-2 border-white">UN</td>
                            <td className="border-r-2 border-white">UN</td>
                            <td style={{ color: "#ff0000" }}>%</td>
                        </tr>
                        <tr style={{ background: "#E7E6E6" }}>
                            <td className="border-r-2 border-white">
                                {propertyInfo?.cadastro}
                            </td>
                            <td className="border-r-2 border-white">
                                {propertyInfo?.logradouronumero}
                            </td>
                            <td className="border-r-2 border-white">
                                {propertyInfo?.logradouronumero}
                            </td>
                            <td className="border-r-2 border-white">-</td>
                            <td className="border-r-2 border-white">-</td>
                            <td className="border-r-2 border-white">-</td>
                            <td style={{ color: "#ff0000" }}>-</td>
                        </tr>
                    </tbody>
                </table>
            </Row>
        </>
    );
};
