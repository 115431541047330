import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.header`
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const DotLine = styled.div`
    width: 100%;
    border-bottom: 2px dashed #666;
    margin-bottom: 10px;
    margin-top: 10px;
`;

export const Line = styled.div`
    width: 100%;
    border-bottom: 2px solid #666;
    margin-bottom: 10px;
    margin-top: 10px;
`;
export const GlobalStyle = createGlobalStyle`
@media print {
    @page {
        margin-top: 0.40cm;
        margin-left: 1.40cm;
        margin-right: 1.40cm;
        padding-left: 10px;
        padding-top: 2px;
        font-family: 'Times New Roman', Times, serif;
    }
    .container {
        margin-left: auto;
        margin-right: auto;
        min-width: 350px;
        max-width: 1000px;
        font-family: "Times New Roman", Times, serif;
    }
    
    h3 {
        font-size: 16pt;
        font-weight: bold;
        text-align: center;
        font-family: "Times New Roman", Times, serif;
    }

    h4 {
        font-size: 14pt;
        font-weight: bold;
        text-align: center;
        font-family: "Times New Roman", Times, serif;
    }

    h5 {
        font-size: 12pt;
        font-weight: bold;
        text-align: center;
        font-family: "Times New Roman", Times, serif;
    }

}`;
