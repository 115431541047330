import React from "react";
import environments from "../../../../environments";
import { IpirangaHeaderCemetery } from "./providers/ipiranga";
import { HeaderCemeteryStandard } from "./providers/standard";
import { HeaderCemeteryJacarezinho } from "./providers/jacarezinho";

export const HeaderCemeteryDocs: React.FC = () => {
    switch (environments.client.name) {
        case "ipiranga":
            return <IpirangaHeaderCemetery />;
        case "jcz":
            return <HeaderCemeteryJacarezinho />;
        default:
            return <HeaderCemeteryStandard />;
    }
};
