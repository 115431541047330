export const StandardConclusion: React.FC = () => {
    return (
        <>
            <div className="text-justify text-center">
                <h4 className="justify-center">Alvará de Construção</h4>
                <p>
                    &emsp;&emsp;Quaisquer obras de construção civil e de
                    infraestrutura, de iniciativa pública ou privada, somente
                    poderão ser iniciadas e executadas após análise e aprovação
                    do projeto e emissão do Alvará de Construção, assim como da
                    concessão de licença por demais órgãos competentes, de
                    acordo com as exigências contidas no Código de Obras
                    Municipal, normas correlatas e regulamentações específicas,
                    sob pena de sanções previstas em lei. O Alvará de Construção
                    terá prazo de validade de 2 (dois) anos, podendo ser
                    prorrogado continuadamente por igual período.
                </p>
            </div>
            <div className="text-justify">
                <h4>Tapumes e equipamentos de segurança</h4>
                <p>
                    &emsp;&emsp;Os limites do canteiro de obra deverão ser
                    vedados por tapumes com altura mínima de 2,00 m (dois
                    metros) e redes de proteção, os quais não poderão ocupar
                    mais do que a metade da largura do passeio, mantidos livre
                    no mínimo 1,20m (um metro e vinte centímetros) para o fluxo
                    de pedestres.
                </p>

                <h4>Vistoria Técnica de Conclusão de Obra - CVCO</h4>
                <p>
                    &emsp;&emsp;Concluída a obra, antes do término da vigência
                    do alvará, o proprietário deverá solicitar ao Município o
                    Certificado de Vistoria de Conclusão de Obra - CVCO da
                    edificação, que deverá ser precedido da vistoria efetuada
                    pelo órgão competente. Para a obtenção do Certificado de
                    Vistoria de Conclusão de Obra - CVCO, a calçada deverá estar
                    executada nos termos do Decreto Municipal 36.559/2021, bem
                    como deverá estar quitado o ISS relativo à obra.
                </p>
            </div>
        </>
    );
};
