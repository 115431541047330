import React from "react";
import environments from "../../../../environments";
import { TransladoEnvioStandard } from "./providers/standard";

export const TransladoEnvio: React.FC = () => {
    switch (environments.client.name) {
        default:
            return <TransladoEnvioStandard />;
    }
};
