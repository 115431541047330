import React, { useCallback, useEffect, useState } from "react";

import { SideBars } from "../../../global";

import { SearchFormCemetery } from "./SearchFormCemetery";
import { useAuth } from "../../../../shared/hooks/useAuth";

import { FeaturesEnum } from "../../../../shared/enums/features.enum";
import { useSearchSideBarCemetery } from "../../../../shared/hooks/useSideBar";
import SearchInputsCemeteryConfig from "../../../../config/webgeo/SearchInputsCemeteryConfig";

export const SearchSideBarCemetery: React.FC = () => {
    const { setShouldOpen, shouldOpen } = useSearchSideBarCemetery();
    const { user } = useAuth();
    const [searchInputs, setSearchInputs] = useState<string[]>([]);

    const fetchSearchInputs = useCallback(() => {
        const allowedRessources =
            user.authorizationsByFeatureName[FeaturesEnum.CEMETERY_SEARCH]
                ?.featureAllowedRessources || [];

        const inputs =
            allowedRessources === "*"
                ? Object.keys(SearchInputsCemeteryConfig)
                : allowedRessources.map((ressource) => ressource.name);

        setSearchInputs(Array.from(new Set(inputs)));
    }, [user]);

    useEffect(() => {
        fetchSearchInputs();
    }, [fetchSearchInputs]);

    const RenderSearchForm = useCallback(() => {
        if (searchInputs.length)
            return <SearchFormCemetery inputs={searchInputs} />;

        return <p>Nenhum campo de pesquisa encontrado</p>;
    }, [searchInputs]);

    return (
        <SideBars
            orientation="right"
            handleClose={() => setShouldOpen(false)}
            show={shouldOpen}
        >
            <RenderSearchForm />
        </SideBars>
    );
};
