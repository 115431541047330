import React, { useCallback, useEffect, useState } from "react";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import { ICemeteryDeceased } from "../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { Popup } from "../../../../../global/popup";

interface Props {
    idToRevert?: number | undefined;
    doAfterSubmit(subscription: string): void;
}
export const RevertTransfer: React.FC<Props> = ({
    idToRevert,
    doAfterSubmit,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<{
        show: boolean;
        id: number | undefined;
    }>({ show: true, id: undefined });

    const revertById = useCallback(
        async (id_: number) => {
            try {
                setLoading(true);
                const transferResponse =
                    await CemeteryInfoApiService.getTransferred(id_);

                const reverseTransfer: Partial<ICemeteryDeceased> = {
                    tipo_cadastramento:
                        transferResponse.tipo_sepultamento_anterior,
                    inscricao: transferResponse.inscricao,
                    em_translado: false,
                    translado_externo: false,
                };

                await CemeteryInfoApiService.updateDeceased(
                    transferResponse.id_falecido,
                    reverseTransfer
                );

                await CemeteryInfoApiService.updateTransferred(id_, {
                    foi_revertido: true,
                });

                doAfterSubmit(transferResponse.inscricao as string);

                showToast({
                    type: "success",
                    message: "O translado foi revertido com sucesso",
                });
            } catch (err) {
                ApiErrorHandler(err);
            } finally {
                setLoading(false);
            }
        },
        [doAfterSubmit]
    );

    const handleDelete = async (id_: number | undefined) => {
        if (id_) {
            setPopup({
                show: true,
                id: id_,
            });
        }
    };

    const handleDeleteCancel = () => {
        setPopup({
            show: false,
            id: undefined,
        });
        doAfterSubmit("");
    };

    useEffect(() => {
        if (popup.id) {
            revertById(popup.id);
        }
    }, [popup, revertById]);

    return (
        <div className="bg-gray-50">
            {!loading && popup && popup.show && idToRevert && (
                <Popup
                    phrase="Tem certeza que deseja reverter o translado?"
                    show
                    handleConfirm={() => handleDelete(idToRevert)}
                    handleCancel={handleDeleteCancel}
                />
            )}
        </div>
    );
};
