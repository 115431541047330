import * as Yup from "yup";
import { ICemeteryDeceased } from "../../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";

const schema = Yup.object().shape({
    sexo: Yup.string().required("Campo SEXO é obrigatório"),
    nome: Yup.string().required("Campo NOME é obrigatório"),
    data_nascimento: Yup.string().required(
        "Campo DATA NASCIMENTO é obrigatório"
    ),
    data_falecimento: Yup.string().required(
        "Campo DATA FALECIMENTO é obrigatório"
    ),
    data_sepultamento: Yup.string().required(
        "Campo DATA SEPULTAMENTO é obrigatório"
    ),
    nome_mae: Yup.string().required("Campo NOME DA MÃE é obrigatório"),

    declaracao: Yup.string().required(
        "Campo Nº DECLARAÇÃO DE ÓBITO é obrigatório"
    ),
    atestado: Yup.string().required("Campo Nº ATESTADO DE ÓBITO é obrigatório"),
    cartorio: Yup.string().required("Campo CARTÓRIO é obrigatório"),
    funeraria: Yup.string().required("Campo FUNERÁRIA é obrigatório"),
    inscricao: Yup.string().required(),
    nome_pai: Yup.string().notRequired(),
    bookForm: Yup.string().notRequired(),
    paperForm: Yup.string().notRequired(),
    data_exumacao: Yup.string().notRequired(),
    controle: Yup.string().notRequired(),
    tipo_cadastramento: Yup.string().required(
        "Campo tipo_cadastramento é obrigatório"
    ),
    translado_cidade: Yup.string().required("Campo CIDADE é obrigatório"),
    translado_origem: Yup.string().required("Campo ORIGEM é obrigatório"),
});

export const deceasedTransferredValidator = async (
    payload: ICemeteryDeceased
) => {
    await schema.validate(payload, {
        abortEarly: false,
    });
};
