import React from "react";
import { Container } from "./styles";
import { ClientLogo } from "../../../../../shared/assets/logos";

export const IpirangaHeaderCemetery: React.FC = () => {
    return (
        <Container>
            <div>
                <div
                    className="absolute"
                    style={{ width: "150px", marginTop: "35px" }}
                >
                    <img src={ClientLogo} alt="logo cidade" />
                </div>
                <div className="grid grid-cols-6 gap-1 pt-2 ">
                    <div className="col-span-6 text-center leading-tight">
                        <h3 className="leading-tight">
                            PREFEITURA MUNICIPAL DE IPIRANGA
                        </h3>
                        <h5 className="leading-tight">
                            SECRETARIA MUNICIPAL DA FAZENDA
                        </h5>
                        <h5 className="leading-tight">
                            CEMITÉRIO MUNICIPAL DE IPIRANGA
                        </h5>
                        Rua: XV de Novembro, 545, centro, Ipiranga, PR
                        <br />
                        Fone: 42 3242-8500/3242-8522
                        <br />
                        <span style={{ fontWeight: "bold" }}>
                            CNPJ: 76.175.934/0001-26
                        </span>
                        <br />
                        Site:&nbsp;
                        <a
                            href="www.ipiranga.pr.gov.br"
                            target="_blank"
                            rel="noreferrer"
                        >
                            www.ipiranga.pr.gov.br
                        </a>
                        <p>
                            E-mail:&nbsp;
                            <a
                                href="mailto:tributacao@ipiranga.pr.gov.br"
                                target="_blank"
                                rel="noreferrer"
                            >
                                tributacao@ipiranga.pr.gov.br
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
};
