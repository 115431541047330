/* eslint-disable import/no-duplicates */
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import React from "react";
import { ILayers } from "../../../../../shared/interfaces/ILayers";
import { ICemeteryResponse } from "../../../../../shared/providers/cemetery/entities/cemetery.interface";
import { DotLine, Line } from "../../header/providers/styles";

import { FirstCroquiCemetery } from "../croqui/croqui";

export interface Props {
    cemeteryInfo: ICemeteryResponse;
    layers: ILayers[];
}

export const BodyBcm: React.FC<Props> = ({ cemeteryInfo, layers }) => {
    // const formattedData = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
    //     locale: ptBR,
    // });
    const today = format(new Date(), "dd  MMMM  yyyy", {
        locale: ptBR,
    });

    const withinValidDate = (data_cadastramento: string) => {
        const start = new Date(data_cadastramento);
        const now = new Date();

        const diffInDays = Math.floor(
            (Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) -
                Date.UTC(
                    start.getFullYear(),
                    start.getMonth(),
                    start.getDate()
                )) /
                (1000 * 60 * 60 * 24)
        );

        if (diffInDays < 20) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div className="w-full flex justify-center">
                <h4 className="font-bold">BOLETIM DE CADASTRO MORTUÁRIO</h4>
            </div>
            <div className="w-full flex justify-end">
                <p>Emitido em: {today}</p>
                <br />
            </div>
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">MAPA DE LOCALIZAÇÃO DO JAZIGO</h5>
            </div>
            {cemeteryInfo.geom && (
                <FirstCroquiCemetery
                    layers={layers}
                    propertyGeom={cemeteryInfo.geom}
                />
            )}
            {cemeteryInfo && (
                <>
                    {" "}
                    <DotLine />
                    <div className="w-full flex justify-center">
                        <h5 className="font-bold">PROPRIETÁRIO</h5>
                    </div>
                    <div className="w-full grid grid-cols-3">
                        <p className="col-span-2">
                            <strong>PROPRIETÁRIO: </strong>
                            {cemeteryInfo.propnome}
                        </p>
                        <p>
                            <strong>SEXO: </strong>
                            {cemeteryInfo.propsexo}
                        </p>
                        <p>
                            <strong>CPF: </strong>
                            {cemeteryInfo.propdocumento}
                        </p>
                        <p>
                            <strong>RG: </strong>
                            {cemeteryInfo.proprg}
                        </p>
                        <p>
                            <strong>DATA DE NASCIMENTO: </strong>
                            {cemeteryInfo.propnascimento}
                        </p>
                        <p className="col-span-2">
                            <strong>LOGRADOURO: </strong>
                            {cemeteryInfo.logradouronome}
                        </p>
                        <p>
                            <strong>NÚMERO: </strong>
                            {cemeteryInfo.logradouronumero}
                        </p>
                        <p>
                            <strong>BAIRRO: </strong>
                            {cemeteryInfo.bairronome}
                        </p>
                        <p>
                            <strong>CIDADE: </strong>
                            {cemeteryInfo.cidade}
                        </p>
                        <p>
                            <strong>ESTADO: </strong>
                            {cemeteryInfo.estado}
                        </p>
                        <p className="col-span-2">
                            <strong>TELEFONE: </strong>
                            {cemeteryInfo.telefone}
                        </p>
                        <p>
                            <strong>EMAIL: </strong>
                            {cemeteryInfo.email}
                        </p>
                        <p className="col-span-2">
                            <strong>NACIONALIDADE: </strong>
                            {cemeteryInfo.nacionalidade}
                        </p>
                        <p>
                            <strong>ESTADO CIVIL: </strong>
                            {cemeteryInfo.estado_civil}
                        </p>
                        <p className="col-span-3">
                            <strong>NÚMERO DO TERMO DE POSSE: </strong>
                            {cemeteryInfo.numero_termo_posse}
                        </p>
                    </div>
                </>
            )}
            {cemeteryInfo.grave && (
                <>
                    <Line />
                    <div className="w-full flex justify-center">
                        <h5 className="font-bold">JAZIGO</h5>
                    </div>

                    <div className="w-full grid grid-cols-6">
                        <p className="col-span-3">
                            <strong>INSCRIÇÃO: </strong>
                            {cemeteryInfo.grave.inscricao}
                        </p>
                        <p className="col-span-3">
                            <strong>CONTROLE: </strong>
                            {cemeteryInfo.geom?.controle}
                        </p>
                        <p>
                            <strong>DISTRITO: </strong>
                            {cemeteryInfo.geom?.distrito}
                        </p>
                        <p>
                            <strong>SETOR: </strong>
                            {cemeteryInfo.geom?.setor}
                        </p>
                        <p>
                            <strong>QUADRA: </strong>
                            {cemeteryInfo.geom?.quadra}
                        </p>
                        <p>
                            <strong>LOTE: </strong>
                            {cemeteryInfo.geom?.lote}
                        </p>
                        <p>
                            <strong>Q LOTE: </strong>
                            {cemeteryInfo.geom?.quadra_lot}
                        </p>
                        <p>
                            <strong>L LOTE: </strong>
                            {cemeteryInfo.geom?.lote_lot}
                        </p>
                        <p className="col-span-2">
                            <strong>SITUAÇÃO: </strong>
                            {cemeteryInfo.grave.situacao}
                        </p>
                        <p className="col-span-2">
                            <strong>OCUPAÇÃO: </strong>
                            {cemeteryInfo.grave.ocupacao}
                        </p>
                        <p className="col-span-2">
                            <strong>ESPÉCIE: </strong>
                            {cemeteryInfo.grave.especie}
                        </p>
                        <p className="col-span-3">
                            <strong>LARGURA: </strong>
                            {cemeteryInfo.grave.largura}m²
                        </p>
                        <p className="col-span-3">
                            <strong>COMPRIMENTO: </strong>
                            {cemeteryInfo.grave.comprimento}m²
                        </p>
                    </div>
                </>
            )}
            {cemeteryInfo &&
                cemeteryInfo.deceased &&
                cemeteryInfo.deceased.map((deceased) => (
                    <>
                        <Line />
                        <div className="w-full flex justify-center">
                            <h5 className="font-bold">FALECIDOS</h5>
                        </div>
                        <div
                            className="w-full grid grid-cols-6"
                            key={`${deceased.id}`}
                        >
                            <p className="col-span-4">
                                <strong>FALECIDO: </strong>
                                {deceased.nome}
                            </p>
                            <p className="col-span-2">
                                <strong>SEXO: </strong>
                                {deceased.sexo}
                            </p>
                            <p className="col-span-6">
                                <strong>TIPO DE SEPULTAMENTO: </strong>
                                {deceased.tipo_cadastramento}
                            </p>
                            <p className="col-span-2">
                                <strong>DATA DE NASCIMENTO: </strong>
                                {deceased.data_nascimento}
                            </p>
                            <p className="col-span-2">
                                <strong>DATA DE FALECIMENTO: </strong>
                                {deceased.data_falecimento}
                            </p>
                            <p className="col-span-2">
                                <strong>DATA DE SEPULTAMENTO: </strong>
                                {deceased.data_sepultamento}
                            </p>
                            <p className="col-span-6">
                                <strong>NOME DA MÃE: </strong>
                                {deceased.nome_mae}
                            </p>
                            <p className="col-span-3">
                                <strong>NOME DO PAI: </strong>
                                {deceased.nome_pai}
                            </p>
                            <p className="col-span-3">
                                <strong>NÚMERO DA DECLARAÇÃO DE ÓBITO: </strong>
                                {deceased.declaracao}
                            </p>
                            <p className="col-span-3">
                                <strong>NÚMERO DA CERTIDÃO DE ÓBITO: </strong>
                                {deceased.atestado}
                            </p>
                            <p className="col-span-3">
                                <strong>CARTÓRIO: </strong>
                                {deceased.cartorio}
                            </p>
                            <p className="col-span-4">
                                <strong>FUNERÁRIA: </strong>
                                {deceased.cartorio}
                            </p>
                            <p className="col-span-1">
                                <strong>LIVRO: </strong>
                                {deceased.cartorio}
                            </p>
                            <p className="col-span-1">
                                <strong>FOLHA: </strong>
                                {deceased.cartorio}
                            </p>
                            <p className="col-span-2">
                                <strong>DATA DE EXUMAÇÃO: </strong>
                                {deceased.data_exumacao}
                            </p>
                            <p className="col-span-2">
                                <strong>INSCRIÇÃO MORTUÁRIA: </strong>
                                {deceased.inscricao_falecido}
                            </p>
                            <p className="col-span-2">
                                <strong>CONTROLE: </strong>
                                {deceased.controle}
                            </p>
                        </div>
                        <DotLine />
                    </>
                ))}

            {cemeteryInfo &&
                cemeteryInfo.workers &&
                cemeteryInfo.workers.map((worker) => (
                    <>
                        <Line />
                        <div className="w-full flex justify-center">
                            <h5 className="font-bold">PRESTADORES</h5>
                        </div>
                        <div key={`${worker.id}`}>
                            {worker.tipo_prestador === "PROPRIETÁRIO" && (
                                <div className="w-full grid grid-cols-6">
                                    <p className="col-span-6">
                                        <strong>NOME DO PROPRIETÁRIO: </strong>
                                        {cemeteryInfo.propnome}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>
                                            DOCUMENTO DO PROPRIETÁRIO:{" "}
                                        </strong>
                                        {cemeteryInfo.propdocumento}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>TIPO DO SERVIÇO: </strong>
                                        {worker.tipo_servico}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>TELEFONE: </strong>
                                        {cemeteryInfo.telefone}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>EMAIL: </strong>
                                        {cemeteryInfo.email}
                                    </p>
                                </div>
                            )}

                            {worker.tipo_prestador === "EMPRESA" && (
                                <div className="w-full grid grid-cols-6">
                                    <p className="col-span-3">
                                        <strong>NOME DO RESPONSÁVEL: </strong>
                                        {worker.responsavel_empresa}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>DOCUMENTO DA EMPRESA: </strong>
                                        {worker.documento_empresa}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>RAZÃO SOCIAL: </strong>
                                        {worker.razao_social_empresa}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>TIPO DE SERVIÇO: </strong>
                                        {worker.tipo_servico_empresa}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>TELEFONE: </strong>
                                        {worker.telefone_empresa}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>EMAIL: </strong>
                                        {worker.email_empresa}
                                    </p>
                                </div>
                            )}

                            <div
                                className="w-full grid grid-cols-6"
                                key={`${worker.id}`}
                            >
                                <p className="col-span-6">
                                    INFORMAÇÃO DO COLABORADOR
                                </p>
                                <p className="col-span-6">
                                    <strong>NOME DO COLABORADOR: </strong>
                                    {worker.nome_colaborador}
                                </p>
                                <p className="col-span-3">
                                    <strong>CPF DO COLABORADOR: </strong>
                                    {worker.cpf_colaborador}
                                </p>
                                <p className="col-span-3">
                                    <strong>FUNÇÃO DO COLABORADOR: </strong>
                                    {worker.funcao_colaborador}
                                </p>
                                <p className="col-span-3">
                                    <strong>TELEFONE DO COLABORADOR: </strong>
                                    {worker.telefone_colaborador}
                                </p>
                                <p className="col-span-3">
                                    <strong>EMAIL DO COLABORADOR: </strong>
                                    {worker.email_colaborador}
                                </p>
                            </div>
                            <DotLine />
                        </div>
                    </>
                ))}
            <Line />

            {cemeteryInfo &&
                cemeteryInfo.transferred &&
                cemeteryInfo.transferred.map((transfer) => (
                    <div key={`${transfer.id}`}>
                        {transfer.tipo_translado === "TRANSLADO INTERNO" && (
                            <>
                                <DotLine />
                                <div className="w-full flex justify-center">
                                    <h5 className="font-bold">
                                        TRANSLADO INTERNO
                                    </h5>
                                </div>
                                <div className="w-full grid grid-cols-6">
                                    <p className="col-span-3">
                                        <strong>NOME DO FALECIDO: </strong>
                                        {transfer.nome}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>NOME DO SOLICITANTE: </strong>
                                        {cemeteryInfo.propnome}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>QUADRA DE REGISTRO: </strong>
                                        {cemeteryInfo.geom?.quadra}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>LOTE DE REGISTRO: </strong>
                                        {cemeteryInfo.geom?.lote}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>QUADRA DE DESTINO: </strong>
                                        {transfer.quadra}
                                    </p>
                                    <p className="col-span-3">
                                        <strong>LOTE DE DESTINO: </strong>
                                        {transfer.lote}
                                    </p>
                                </div>
                            </>
                        )}

                        {transfer.tipo_translado === "TRANSLADO ENVIO" && (
                            <>
                                <DotLine />
                                <div className="w-full flex justify-center">
                                    <h5 className="font-bold">
                                        TRANSLADO DE ENVIO
                                    </h5>
                                </div>
                                <div className="w-full grid grid-cols-6">
                                    <p className="col-span-3">
                                        <strong>NOME DO FALECIDO: </strong>
                                        {transfer.nome}
                                    </p>
                                    <p className="col-span-2">
                                        <strong>INSCRIÇÃO: </strong>
                                        {transfer.inscricao_falecido}
                                    </p>
                                    <p className="col-span-6">
                                        INFORMAÇÕES DO CEMITÉRIO DESTINO
                                    </p>
                                    <p className="col-span-6">
                                        <strong>CEMITÉRIO DESTINO: </strong>
                                        {transfer.cemiterio_destino}
                                    </p>
                                    <p className="col-span-2">
                                        <strong>QUADRA: </strong>
                                        {transfer.quadra}
                                    </p>
                                    <p className="col-span-2">
                                        <strong>LOTE: </strong>
                                        {transfer.lote}
                                    </p>
                                    <p className="col-span-2">
                                        <strong>JAZIGO: </strong>
                                        {transfer.jazigo}
                                    </p>
                                    <p className="col-span-2">
                                        <strong>CIDADE: </strong>
                                        {transfer.cidade}
                                    </p>
                                    <p className="col-span-2">
                                        <strong>ESTADO: </strong>
                                        {transfer.estado}
                                    </p>
                                    <p className="col-span-2">
                                        <strong>PAÍS: </strong>
                                        {transfer.pais}
                                    </p>
                                </div>
                            </>
                        )}
                        <div className="w-full grid grid-cols-6">
                            <p className="col-span-3">
                                <strong>O TRANSLADO FOI REVERTIDO?: </strong>
                                {transfer.foi_revertido ? "SIM" : "NÃO"}
                            </p>
                            <p className="col-span-3">
                                <strong>VALIDADE: </strong>
                                {withinValidDate(transfer.data_atualizacao)
                                    ? "VÁLIDO"
                                    : "INVÁLIDO"}
                            </p>
                        </div>
                    </div>
                ))}
            <Line />
            {cemeteryInfo.taxes && (
                <>
                    <DotLine />
                    <div className="w-full flex justify-center">
                        <h5 className="font-bold">TAXAS</h5>
                    </div>
                    <div className="w-full grid grid-cols-6">
                        {cemeteryInfo.taxes.terreno && (
                            <p className="col-span-3">
                                <strong>TERRENO: </strong>
                                R$ {cemeteryInfo.taxes.terreno}
                            </p>
                        )}
                        {cemeteryInfo.taxes.sepultamento && (
                            <p className="col-span-3">
                                <strong>SEPULTAMENTO: </strong>
                                R$ {cemeteryInfo.taxes.sepultamento}
                            </p>
                        )}
                        {cemeteryInfo.taxes.construcao && (
                            <p className="col-span-3">
                                <strong>AQUISIÇÃO DE JAZIGO: </strong>
                                R$ {cemeteryInfo.taxes.construcao}
                            </p>
                        )}
                        {cemeteryInfo.taxes.translado && (
                            <p className="col-span-6">
                                <strong>TRANSLADO: </strong>
                                R$ {cemeteryInfo.taxes.translado}
                            </p>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
