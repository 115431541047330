export const CemeteryFeaturesLabels: Record<
    string,
    { label: string; order: number; actions: string[] }
> = {
    cemetery_grave: {
        label: "Aba jazigo",
        order: 1,
        actions: ["create", "read", "update", "remove"],
    },
    cemetery_owner: {
        label: "Aba proprietário",
        order: 2,
        actions: ["create", "update", "remove"],
    },
    cemetery_deceased: {
        label: "Aba falecidos",
        order: 3,
        actions: ["create", "read", "update", "remove"],
    },
    cemetery_workers: {
        label: "Aba prestadores",
        order: 4,
        actions: ["create", "read", "update", "remove"],
    },
    cemetery_workers_companies: {
        label: "Aba prestadores > empresas prestadoras",
        order: 5,
        actions: ["create"],
    },
    cemetery_taxes: {
        label: "Aba taxas",
        order: 6,
        actions: ["create", "update", "remove"],
    },
    cemetery_attachments: {
        label: "Aba anexos (apenas visualização no webgeo)",
        order: 7,
        actions: ["update"],
    },
    cemetery_transferred: {
        label: "Aba translado",
        order: 8,
        actions: ["create", "read", "update", "remove"],
    },
    bcm: {
        label: "BCM (Documento)",
        order: 9,
        actions: ["read"],
    },
    modelo_posse: {
        label: "Modelo de Posse (Documento)",
        order: 10,
        actions: ["read"],
    },
    abertura_tumulo: {
        label: "Abertura de túmulo (Documento)",
        order: 11,
        actions: ["read"],
    },
    autorizacao_reforma: {
        label: "Autorização de reforma (Documento)",
        order: 12,
        actions: ["read"],
    },
    translado_interno: {
        label: "Translado Interno (Documento)",
        order: 13,
        actions: ["read"],
    },
    translado_externo: {
        label: "Translado DE ENVIO (Documento)",
        order: 14,
        actions: ["read"],
    },
    cemetery_search: {
        label: "BUSCA CEMITÉRIO",
        order: 15,
        actions: ["read"],
    },
    cemetery_sub_fields: {
        label: "CADASTRAR NA ABA CADASTRAMENTO",
        order: 16,
        actions: ["create"],
    },
};

export const CemeteryRessourcesLabels: Record<string, string> = {
    inscricaoimobiliaria: "Inscrição",
    propnome: "Nome proprietário",
    propdocumento: "CPF proprietário",
    proprg: "RG proprietário",
    id: "Id (marcar sempre)",
    propsexo: "Sexo",
    propnascimento: "Data de nascimento",
    logradouronome: "Nome logradouro",
    bairronome: "Nome bairro",
    logradouronumero: "Nro logradouro",
    cidade: "Cidade",
    estado: "Estado",
    telefone: "Telefone",
    email: "Email",
    nacionalidade: "Nacionalidade",
    estado_civil: "Estado civil",
    numero_termo_posse: "Nro termo de posse",
    inscricao: "Inscrição",
    data_atualizacao: "Data atualização",
    falecido: "Informação dos falecidos",
    controle: "Campo controle",
    nome: "Nome do falecido",
    data_falecimento: "Data falecimento",
    data_sepultamento: "Data sepultamento",
    nome_mae: "Nome da mãe",
    nome_pai: "Nome do pai",
    declaracao: "Declaração de óbito",
    atestado: "Nro do atesado de óbito",
    cartorio: "Cartório",
    funeraria: "Funerária",
    livro: "Livro",
    folha: "Folha",
    data_exumacao: "Data Exumação",
    inscricao_falecido: "Inscrição mortuária",
    tipo_cadastramento: "Tipo cadastramento",
    translado_origem: "Translado origem",
    translado_cidade: "Translado cidade",
    em_translado: "Em translado",
    translado_externo: "Translado DE ENVIO",
    terreno: "Valor Terreno",
    sepultamento: "Valor Sepultamento",
    construcao: "Valor AQUISIÇÃO DE JAZIGO",
    translado: "Valor Translado",
    nome_responsavel: "Nome responsável",
    cpf: "CPF",
    razao_social: "Razão Social",
    cnpj: "CNPJ",
    tipo_servico: "Tipo serviço",
    data_cadastramento: "Data cadastramento",
    tipo_prestador: "Tipo prestador",
    nome_colaborador: "Nome colaborador",
    cpf_colaborador: "CPF colaborador",
    telefone_colaborador: "Telefone colaborador",
    email_colaborador: "Email colaborador",
    funcao_colaborador: "Função colaborador",
    responsavel_empresa: "Responsável empresa",
    documento_empresa: "Documento empresa",
    razao_social_empresa: "Razão social",
    tipo_servico_empresa: "Tipo servico empresa",
    telefone_empresa: "Telefone empresa",
    email_empresa: "Email empresa",
    id_falecido: "Id falecido",
    tipo_sepultamento_anterior: "Tipo sepultamento anterior",
    lote: "Lote",
    quadra: "Quadra",
    cemiterio_destino: "Cemitério destino",
    jazigo: "Jazigo",
    pais: "País",
    tipo_translado: "Tipo translado",
    foi_revertido: "Foi revertido",
    anexos: "Anexos",
    observacao: "Observação",
    created_by: "Criado por",
    situacao: "Situação",
    ocupacao: "Ocupacão",
    especie: "Espécie",
    largura: "Largura",
    comprimento: "Comprimento",
    unidade: "Unidade",
    sexo: "Sexo",
    data_nascimento: "Data nascimento",
    quadra_lot: "Q Lot",
    lote_lot: "L Lot",
    geom: "Geom (Sempre marcar)",
    distrito: "Distrito",
    area: "Área",
    setor: "Setor",
};
