import { ICemeteryDeceasedResponse } from "../../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { Input, InputMask, Select } from "../../../../../../global";
import { FuneralComponent } from "./funeral";
import { RegisteryComponent } from "./registery";

interface Props {
    deceasedInfo?: Partial<ICemeteryDeceasedResponse>;
    control?: string | undefined;
    sequenceNumber: string;
    subscription: string | undefined;
}
export const SepultamentoAddComponent: React.FC<Props> = ({
    deceasedInfo,
    control,
    sequenceNumber,
    subscription,
}) => {
    return (
        <div>
            <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                <Input
                    name="nameForm"
                    label="NOME"
                    defaultValue={deceasedInfo?.nome}
                />
                <Select
                    name="genderForm"
                    label="SEXO"
                    defaultValue={{
                        label: deceasedInfo?.sexo,
                        value: deceasedInfo?.sexo,
                    }}
                    options={[
                        {
                            label: "FEMININO",
                            value: "FEMININO",
                        },
                        {
                            label: "MASCULINO",
                            value: "MASCULINO",
                        },
                    ]}
                />
            </div>
            <div className="grid gap-1 grid-cols-4 grid-rows-1 relative">
                <InputMask
                    name="birthDateForm"
                    label="NASCIMENTO"
                    mask="99/99/9999"
                />

                <InputMask
                    name="deathDateForm"
                    label="FALECIMENTO"
                    mask="99/99/9999"
                />

                <InputMask
                    name="burialDateForm"
                    label="SEPULTAMENTO"
                    mask="99/99/9999"
                />

                <InputMask
                    name="exhumationDateForm"
                    label="EXUMAÇÃO"
                    mask="99/99/9999"
                />
            </div>
            <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                <Input
                    name="motherNameForm"
                    label="NOME DA MÃE"
                    defaultValue={deceasedInfo?.nome_mae}
                />
                <Input
                    name="fatherNameForm"
                    label="NOME DO PAI"
                    defaultValue={deceasedInfo?.nome_pai}
                />
                <Input
                    name="declarationForm"
                    label="NÚMERO DA DECLARAÇÃO DE ÓBITO"
                    defaultValue={deceasedInfo?.declaracao}
                />
                <Input
                    name="atestadoForm"
                    label="NÚMERO DA CERTIDÃO DE ÓBITO"
                    defaultValue={deceasedInfo?.atestado}
                />

                <RegisteryComponent />

                <FuneralComponent />

                <Input
                    name="bookForm"
                    label="LIVRO"
                    defaultValue={deceasedInfo?.livro}
                />
                <Input
                    name="paperForm"
                    label="FOLHA"
                    defaultValue={deceasedInfo?.folha}
                />
                <Input
                    name="subscriptionForm"
                    label="INSCRIÇÃO"
                    defaultValue={subscription}
                    disabled
                    hidden
                />
                <Input
                    name="controlForm"
                    label="CONTROLE"
                    defaultValue={`${control}-${sequenceNumber}`}
                    disabled
                    hidden
                />
                <Input
                    name="inscricaoFalecidoForm"
                    label="SEQUENCIA"
                    defaultValue={`${subscription}.${sequenceNumber}`}
                    disabled
                    hidden
                />
            </div>
        </div>
    );
};
