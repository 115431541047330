import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { MainButton, Select } from "../../../../../global";
import { Input } from "../../../../../global/input/index";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import {
    ICemeteryGrave,
    ICemeteryGraveForm,
} from "../../../../../../shared/providers/cemetery/entities/grave-cemetery.interface";
import { graveValidator } from "./components/validator";
import { yupValidationErrorByField } from "../../../../../../shared/utils/yup-error-by-field";

interface AddFormProps {
    doAfterSubmit(subscription: string): void;
    subscription?: string;
    infoLayer?: Record<string, string>;
}
export const AddGrave: React.FC<AddFormProps> = ({
    doAfterSubmit,
    subscription,
    infoLayer,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async ({
        situacaoForm,
        ocupacaoForm,
        especieForm,
        larguraForm,
        comprimentoForm,
        distritoForm,
        setorForm,
        quadraForm,
        quadraLotForm,
        loteForm,
        loteLotForm,
        areaForm,
        controleForm,
    }: ICemeteryGraveForm) => {
        const payload: ICemeteryGrave = {
            situacao: situacaoForm,
            ocupacao: ocupacaoForm,
            especie: especieForm,
            largura: larguraForm,
            comprimento: comprimentoForm,
            distrito: distritoForm,
            setor: setorForm,
            quadra: quadraForm,
            quadra_lot: quadraLotForm,
            lote: loteForm,
            lote_lot: loteLotForm,
            area: areaForm,
            controle: controleForm as string | "",
            inscricao: subscription as string | "",
        };
        try {
            setLoading(true);
            await graveValidator(payload);

            await CemeteryInfoApiService.createGrave(payload);

            showToast({
                type: "success",
                message: "Dados do jazigo foram adicionados com sucesso",
            });

            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-50">
            <p>Adicionar Jazigo</p>
            {!loading && (
                <Form
                    onSubmit={handleSubmit}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <div className="grid gap-1 grid-cols-2 grid-rows-1 relative">
                        <Input
                            name="inscricaoForm"
                            label="INSCRIÇÃO"
                            value={subscription}
                            hidden
                            disabled
                        />
                        <Input
                            name="controleForm"
                            label="CONTROLE"
                            value={infoLayer?.controle}
                            hidden
                            disabled
                        />
                        <Input
                            name="distritoForm"
                            label="DISTRITO"
                            value={infoLayer?.distrito}
                            hidden
                            disabled
                        />
                        <Input
                            name="setorForm"
                            label="SETOR"
                            value={infoLayer?.setor}
                            hidden
                            disabled
                        />
                        <Input
                            name="quadraForm"
                            label="QUADRA"
                            value={infoLayer?.quadra}
                            hidden
                            disabled
                        />
                        <Input
                            name="loteForm"
                            label="LOTE"
                            value={infoLayer?.lote}
                            hidden
                            disabled
                        />
                        <Input
                            name="quadraLotForm"
                            label="Q - LOT"
                            value={infoLayer?.quadra_lot}
                            disabled
                        />
                        <Input
                            name="loteLotForm"
                            label="L - LOT"
                            value={infoLayer?.lote_lot}
                            disabled
                        />

                        <Select
                            name="situacaoForm"
                            label="SITUAÇÃO"
                            placeholder="Selecione..."
                            options={[
                                {
                                    label: "PRECÁRIA",
                                    value: "PRECÁRIA",
                                },
                                {
                                    label: "RUIM",
                                    value: "RUIM",
                                },
                                {
                                    label: "BOA",
                                    value: "BOA",
                                },
                                {
                                    label: "ÓTIMA",
                                    value: "ÓTIMA",
                                },
                            ]}
                        />
                        <Select
                            name="ocupacaoForm"
                            label="OCUPAÇÃO"
                            placeholder="Selecione..."
                            options={[
                                {
                                    label: "CONSTRUÍDO",
                                    value: "CONSTRUÍDO",
                                },
                                {
                                    label: "VAZIO",
                                    value: "VAZIO",
                                },
                            ]}
                        />
                        <Select
                            name="especieForm"
                            label="ESPÉCIE"
                            placeholder="Selecione..."
                            options={[
                                {
                                    label: "PÚBLICO",
                                    value: "PÚBLICO",
                                },
                                {
                                    label: "PARTICULAR",
                                    value: "PARTICULAR",
                                },
                            ]}
                        />
                        <Input name="larguraForm" label="LARGURA EM (m²)" />
                        <Input
                            name="comprimentoForm"
                            label="COMPRIMENTO EM (m²)"
                        />
                    </div>
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-2">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};
