import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { MainButton } from "../../../../../global";
import { Input } from "../../../../../global/input/index";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import { ICemeteryTaxes } from "../../../../../../shared/providers/cemetery/entities/taxes-cemetery.interface";

interface AddFormProps {
    doAfterSubmit(subscription: string): void;
    subscription?: string | undefined;
}
export const AddTaxes: React.FC<AddFormProps> = ({
    doAfterSubmit,
    subscription,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmitEdit = async ({
        terrenoForm,
        sepultamentoForm,
        construcaoForm,
        transladoForm,
    }: {
        terrenoForm: string;
        sepultamentoForm: string;
        construcaoForm: string;
        transladoForm: string;
    }) => {
        const payload: ICemeteryTaxes = {
            terreno: terrenoForm,
            sepultamento: sepultamentoForm,
            construcao: construcaoForm,
            translado: transladoForm,
            inscricao: subscription || "",
        };
        try {
            setLoading(true);
            await CemeteryInfoApiService.createTaxes(payload);

            showToast({
                type: "success",
                message: "Dados das taxas foram inseridos com sucesso",
            });

            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="bg-gray-50">
            {!loading && (
                <Form
                    onSubmit={handleSubmitEdit}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <div className="grid gap-1 grid-cols-3 grid-rows-1 relative">
                        <Input
                            name="terrenoForm"
                            label="VALOR DA TAXA DE TERRENO"
                        />
                        <Input
                            name="sepultamentoForm"
                            label="VALOR DA TAXA DE SEPULTAMENTO"
                        />
                        <Input
                            name="construcaoForm"
                            label="VALOR DE AQUISIÇÃO DE JAZIGO"
                        />
                        <Input
                            name="transladoForm"
                            label="VALOR DA TAXA DE TRANSLADO"
                        />
                        <div className="md:w-2/3 h-10 mt-2">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};
