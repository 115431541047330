import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { MainButton } from "../../../../../global";
import {
    ICemeteryOwner,
    ICemeteryOwnerForm,
} from "../../../../../../shared/providers/cemetery/entities/owner-cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import { ownerValidator } from "./components/validator";
import { yupValidationErrorByField } from "../../../../../../shared/utils/yup-error-by-field";
import { OwnerComponent } from "./components/ownerComponent";

interface AddFormProps {
    doAfterSubmit(subscription: string): void;
    subscription?: string | undefined;
}
export const AddOwner: React.FC<AddFormProps> = ({
    doAfterSubmit,
    subscription,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmitEdit = async ({
        propsexoForm,
        propnomeForm,
        propdocumentoForm,
        proprgForm,
        propnascimentoForm,
        logradouronomeForm,
        bairronomeForm,
        logradouronumeroForm,
        cidadeForm,
        estadoForm,
        telefoneForm,
        emailForm,
        nacionalidadeForm,
        estadocivilForm,
        numerotermoposseForm,
    }: ICemeteryOwnerForm) => {
        const payload: ICemeteryOwner = {
            propsexo: propsexoForm,
            propnome: propnomeForm,
            propdocumento: propdocumentoForm,
            proprg: proprgForm,
            propnascimento: propnascimentoForm,
            logradouronome: logradouronomeForm,
            bairronome: bairronomeForm,
            logradouronumero: logradouronumeroForm,
            cidade: cidadeForm,
            estado: estadoForm,
            telefone: telefoneForm,
            email: emailForm,
            nacionalidade: nacionalidadeForm,
            estado_civil: estadocivilForm,
            numero_termo_posse: numerotermoposseForm,
            inscricao: subscription || "",
        };
        try {
            setLoading(true);
            await ownerValidator(payload);

            await CemeteryInfoApiService.createOwner(payload);

            showToast({
                type: "success",
                message: "Dados do proprietário inseridos com sucesso",
            });

            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-50">
            <p>Adicionar Proprietário</p>
            {!loading && (
                <Form
                    onSubmit={handleSubmitEdit}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <OwnerComponent />
                    <div className="md:w-2/3 h-10 mt-10">
                        <MainButton type="submit">SALVAR</MainButton>
                    </div>
                </Form>
            )}
        </div>
    );
};
