/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import {
    Col,
    Form,
    FormGroup,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import {
    BsArrowLeftSquare,
    BsPlusSquare,
    BsPrinter,
    BsTrash,
} from "react-icons/bs";
import { FaUndo } from "react-icons/fa";
import { MdOutlineExpandMore } from "react-icons/md";
import { FeaturesEnum } from "../../../../../../shared/enums/features.enum";
import { useAuth } from "../../../../../../shared/hooks/useAuth";
import { ICemeteryTransferredResponse } from "../../../../../../shared/providers/cemetery/entities/transferred.interface";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { Popup } from "../../../../../global/popup";
import { showToast } from "../../../../../global/toast";
import { AddTransferred } from "./add";
import { ReadTransferred } from "./read";
import env from "../../../../../../environments";
import { RevertTransfer } from "./revert";

interface Props {
    doAfterSubmit(subscription: string): void;
    info?: Partial<ICemeteryTransferredResponse[]>;
    infoLayer?: Record<string, string>;
}

export const Transferred: React.FC<Props> = ({
    doAfterSubmit,
    info,
    infoLayer,
}) => {
    const { user } = useAuth();
    const [enableCreate, setEnableCreate] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [revert, setRevert] = useState<{
        show: boolean;
        id: number | undefined;
    }>();

    const [currentTransferred, setCurrentTransferred] =
        useState<ICemeteryTransferredResponse>();

    const [popup, setPopup] = useState<{
        show: boolean;
        id: number | undefined;
    }>();

    const handleEnableCreate = () => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
    };

    const handleAfterCreateSubmit = (subscription: string) => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    const handleValidate = (data_cadastramento: string | undefined) => {
        if (data_cadastramento) {
            const start = new Date(data_cadastramento);
            const now = new Date();

            const diffInDays = Math.floor(
                (Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) -
                    Date.UTC(
                        start.getFullYear(),
                        start.getMonth(),
                        start.getDate()
                    )) /
                    (1000 * 60 * 60 * 24)
            );

            if (diffInDays < 20) {
                return "VÁLIDO";
            }
            return "EXPIRADO";
        }
        return "INVÁLIDO";
    };

    const handleAfterRevertTransfer = (subscription: string) => {
        setRevert({
            show: false,
            id: undefined,
        });
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    const handleRevert = async (id_: number | undefined) => {
        if (id_) {
            setRevert({
                show: true,
                id: id_,
            });
        }
    };

    const handleDelete = async (id_: number | undefined) => {
        if (id_) {
            setPopup({
                show: true,
                id: id_,
            });
        }
    };

    const handleDeleteCancel = () => {
        setPopup({
            show: false,
            id: undefined,
        });
    };

    const handleDeleteConfirm = useCallback(async () => {
        if (popup && popup.show && popup.id) {
            try {
                if (popup.id) {
                    setLoading(true);

                    await CemeteryInfoApiService.deleteTransferred(popup.id);

                    showToast({
                        message: `As informações do translado foram deletadas com sucesso`,
                        type: "success",
                    });

                    doAfterSubmit(infoLayer?.inscricao as string);
                }
            } catch (err) {
                ApiErrorHandler(err, "warn");
            } finally {
                setLoading(false);
            }
        }
        setPopup({
            show: false,
            id: undefined,
        });
    }, [popup]);

    return (
        <>
            {!currentTransferred && (
                <div className="grid grid-cols-3 grid-rows-1">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {enableCreate
                                    ? "VOLTAR"
                                    : "ADICIONAR TRANSLADO"}
                            </Tooltip>
                        }
                    >
                        <div className="mb-3 flex space-x-2 justify-center">
                            {user.authorizationsByFeatureName[
                                FeaturesEnum.CEMETERY_TRANSFERRED
                            ]?.canCreate && (
                                <button
                                    type="button"
                                    onClick={handleEnableCreate}
                                >
                                    {enableCreate ? (
                                        <BsArrowLeftSquare
                                            color="teal"
                                            size={20}
                                        />
                                    ) : (
                                        <BsPlusSquare color="teal" size={20} />
                                    )}
                                </button>
                            )}
                        </div>
                    </OverlayTrigger>
                </div>
            )}
            {popup && popup.show && (
                <Popup
                    phrase="O translado não será revertido, apenas terá seu registro apagado. Prosseguir?"
                    show
                    handleConfirm={handleDeleteConfirm}
                    handleCancel={handleDeleteCancel}
                />
            )}
            {revert && revert.show && (
                <RevertTransfer
                    idToRevert={revert.id}
                    doAfterSubmit={handleAfterRevertTransfer}
                />
            )}
            {currentTransferred && (
                <ReadTransferred
                    info={currentTransferred}
                    backButton={() => setCurrentTransferred(undefined)}
                />
            )}
            {enableCreate && (
                <AddTransferred
                    subscription={infoLayer?.inscricao}
                    doAfterSubmit={handleAfterCreateSubmit}
                />
            )}
            {!enableCreate &&
                !currentTransferred &&
                info?.map((transferred) => (
                    <FormGroup key={transferred?.id}>
                        {!transferred?.foi_revertido &&
                            handleValidate(transferred?.data_atualizacao) !==
                                "VÁLIDO" && (
                                <Row
                                    style={{
                                        margin: "1rem",
                                        padding: "1rem",
                                        border: "2px solid red",
                                        borderRadius: "1rem",
                                    }}
                                >
                                    <Col>
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            FALECIDO
                                        </Form.Label>
                                        <Form.Control
                                            value={transferred?.nome}
                                            disabled
                                        />

                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            TIPO DE TRANSLADO
                                        </Form.Label>
                                        <Form.Control
                                            value={transferred?.tipo_translado}
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            VALIDADE
                                        </Form.Label>
                                        <Form.Control
                                            value="EXPIRADO"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            AÇÕES
                                        </Form.Label>
                                        <div className="mb-3 flex space-x-2">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {enableCreate
                                                            ? "VOLTAR"
                                                            : "EXPANDIR"}
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="mb-3 flex space-x-2 justify-center">
                                                    {user
                                                        .authorizationsByFeatureName[
                                                        FeaturesEnum
                                                            .CEMETERY_TRANSFERRED
                                                    ]?.canRead && (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                setCurrentTransferred(
                                                                    transferred
                                                                )
                                                            }
                                                        >
                                                            <MdOutlineExpandMore
                                                                color="teal"
                                                                size={25}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </OverlayTrigger>
                                            {transferred &&
                                                transferred.tipo_translado ===
                                                    "TRANSLADO INTERNO" &&
                                                transferred.inscricao && (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                TRANSLADO
                                                                INTERNO
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className="mb-3 flex space-x-2 justify-center">
                                                            {user
                                                                .authorizationsByFeatureName[
                                                                FeaturesEnum
                                                                    .TRANSLADO_INTERNO
                                                            ]?.canRead && (
                                                                <a
                                                                    href={`/translado-interno/${transferred.inscricao}/${transferred.inscricao_falecido}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <BsPrinter
                                                                        color="teal"
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                </a>
                                                            )}
                                                        </div>
                                                    </OverlayTrigger>
                                                )}

                                            {transferred &&
                                                transferred.tipo_translado ===
                                                    "TRANSLADO DE ENVIO" &&
                                                transferred.inscricao && (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                TRANSLADO DE
                                                                ENVIO
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className="mb-3 flex space-x-2 justify-center">
                                                            {user
                                                                .authorizationsByFeatureName[
                                                                FeaturesEnum
                                                                    .TRANSLADO_EXTERNO
                                                            ]?.canRead && (
                                                                <a
                                                                    href={`/translado-envio/${transferred.inscricao}/${transferred.inscricao_falecido}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <BsPrinter
                                                                        color="teal"
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                </a>
                                                            )}
                                                        </div>
                                                    </OverlayTrigger>
                                                )}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        {!transferred?.foi_revertido &&
                            handleValidate(transferred?.data_atualizacao) ===
                                "VÁLIDO" && (
                                <Row
                                    style={{
                                        margin: "1rem",
                                        padding: "1rem",
                                        border: "2px solid teal",
                                        borderRadius: "1rem",
                                    }}
                                >
                                    <Col>
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            FALECIDO
                                        </Form.Label>
                                        <Form.Control
                                            value={transferred?.nome}
                                            disabled
                                        />
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            TIPO DE TRANSLADO
                                        </Form.Label>
                                        <Form.Control
                                            value={transferred?.tipo_translado}
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            FOI REVERTIDO?
                                        </Form.Label>
                                        <Form.Control value="NÃO" disabled />

                                        <Col>
                                            <Form.Label
                                                style={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                VALIDADE
                                            </Form.Label>
                                            <Form.Control
                                                value="VÁLIDO"
                                                disabled
                                            />
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Form.Label
                                            style={{ fontWeight: "bold" }}
                                        >
                                            AÇÕES
                                        </Form.Label>
                                        <Col>
                                            <div className="mb-3 flex space-x-2">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            {enableCreate
                                                                ? "VOLTAR"
                                                                : "EXPANDIR"}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="mb-3 flex space-x-2 justify-center">
                                                        {user
                                                            .authorizationsByFeatureName[
                                                            FeaturesEnum
                                                                .CEMETERY_TRANSFERRED
                                                        ]?.canRead && (
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    setCurrentTransferred(
                                                                        transferred
                                                                    )
                                                                }
                                                            >
                                                                <MdOutlineExpandMore
                                                                    color="teal"
                                                                    size={25}
                                                                />
                                                            </button>
                                                        )}
                                                    </div>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            REVERTER O TRANSLADO
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div>
                                                        {user
                                                            .authorizationsByFeatureName[
                                                            FeaturesEnum
                                                                .CEMETERY_TRANSFERRED
                                                        ]?.canUpdate && (
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleRevert(
                                                                        transferred?.id
                                                                    )
                                                                }
                                                            >
                                                                <FaUndo
                                                                    color="teal"
                                                                    size={20}
                                                                />
                                                            </button>
                                                        )}
                                                    </div>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            APAGAR
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div>
                                                        {user
                                                            .authorizationsByFeatureName[
                                                            FeaturesEnum
                                                                .CEMETERY_TRANSFERRED
                                                        ]?.canRemove && (
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        transferred?.id
                                                                    )
                                                                }
                                                            >
                                                                <BsTrash
                                                                    color="red"
                                                                    size={20}
                                                                />
                                                            </button>
                                                        )}
                                                    </div>
                                                </OverlayTrigger>
                                                {env.webgeo.config.cemiterio &&
                                                    transferred &&
                                                    transferred.tipo_translado ===
                                                        "TRANSLADO INTERNO" &&
                                                    transferred.inscricao && (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    TRANSLADO
                                                                    INTERNO
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div className="mb-3 flex space-x-2 justify-center">
                                                                {user
                                                                    .authorizationsByFeatureName[
                                                                    FeaturesEnum
                                                                        .TRANSLADO_INTERNO
                                                                ]?.canRead && (
                                                                    <a
                                                                        href={`/translado-interno/${transferred.inscricao}/${transferred.inscricao_falecido}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <BsPrinter
                                                                            color="teal"
                                                                            size={
                                                                                20
                                                                            }
                                                                        />
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </OverlayTrigger>
                                                    )}
                                                {transferred &&
                                                    transferred.tipo_translado ===
                                                        "TRANSLADO ENVIO" &&
                                                    transferred.inscricao && (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    TRANSLADO DE
                                                                    ENVIO
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div className="mb-3 flex space-x-2 justify-center">
                                                                {user
                                                                    .authorizationsByFeatureName[
                                                                    FeaturesEnum
                                                                        .TRANSLADO_EXTERNO
                                                                ]?.canRead && (
                                                                    <a
                                                                        href={`/translado-envio/${transferred.inscricao}/${transferred.inscricao_falecido}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <BsPrinter
                                                                            color="teal"
                                                                            size={
                                                                                20
                                                                            }
                                                                        />
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </OverlayTrigger>
                                                    )}
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            )}
                    </FormGroup>
                ))}
        </>
    );
};
