/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { createFilter } from "react-select";
import { SelectOptions } from "../../../../../../../shared/interfaces/ISelectOptions";
import { ICemeteryControlList } from "../../../../../../../shared/providers/cemetery/entities/workers-cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../../../shared/services/api/cemetery-api.service";
import { Input, Select } from "../../../../../../global";

export const ControlComponent: React.FC = () => {
    const [options, setOptions] = useState<SelectOptions[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchedInfo, setFetchedInfo] = useState<ICemeteryControlList[]>([]);
    const [fetchedLote, setLote] = useState<string>();
    const [fetchedQuadra, setQuadra] = useState<string>();
    const [fetchedControl, setControl] = useState<string>();

    const convertOptions = async (opts: ICemeteryControlList[]) => {
        const selectOptions: SelectOptions[] = [];

        opts.forEach((element: ICemeteryControlList) => {
            const option: SelectOptions = {
                label: element.controle,
                value: element.inscricao,
            };
            selectOptions.push(option);
        });

        return selectOptions;
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const controlListResponse =
                await CemeteryInfoApiService.getControlList();

            setFetchedInfo(controlListResponse.data);

            const op = await convertOptions(controlListResponse.data);
            setOptions(op);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOnChange = (value: any) => {
        const fetched = fetchedInfo.find((x) => value.label === x.controle);
        setControl(value.label);
        setQuadra(fetched?.quadra);
        setLote(fetched?.lote);
    };

    return (
        <div>
            {!loading && (
                <>
                    <Select
                        filterOption={createFilter({
                            ignoreAccents: false,
                            ignoreCase: true,
                        })}
                        name="inscricaoBasedOnControleForm"
                        label="Controle Destino"
                        options={options}
                        placeholder="Selecione o controle de destino..."
                        onChange={(value) => handleOnChange(value)}
                    />
                    {fetchedLote && (
                        <Input
                            name="loteForm"
                            label="LOTE"
                            value={fetchedLote}
                            disabled
                        />
                    )}
                    {fetchedQuadra && (
                        <Input
                            name="quadraForm"
                            label="QUADRA"
                            value={fetchedQuadra}
                            disabled
                        />
                    )}
                    {fetchedControl && (
                        <Input
                            name="controleForm"
                            label="CONTROLE"
                            value={fetchedControl}
                            hidden
                            disabled
                        />
                    )}
                </>
            )}
        </div>
    );
};
