import React from "react";

export const PalmeiraGaragem: React.FC = () => {
    return (
        <>
            <h5>ALERTAS INCIDENTES SOBRE VAGAS DE GARAGEM</h5>

            <ul className="pl-0 list-none list-inside">
                <li>
                    ► EM PROJETOS DE AMPLIAÇÃO SERÁ COMPUTADA A ÁREA TOTAL DA
                    EDIFICAÇÃO PARA O CALCULO DO NÚMERO DE VAGAS
                </li>
                <li>
                    ► O CÁLCULO DE VAGAS DE ESTACIONAMENTO SERÁ COMPUTADO SOBRE
                    AS ÁREAS INTERNAS DE ACESSO PÚBLICO
                    <ul className="list-disc list-inside">
                        <li>
                            SÃO ÁREAS INTERNAS DE ACESSO PRIVATIVO: ESCRITÓRIOS,
                            DEPÓSITOS, VESTIARIOS E BANHEIROS, COZINHAS,
                            GARAGENS COBERTAS
                        </li>
                        <li>
                            ACESSOS PRIVATIVOS NÃO CONSTANTES DO ITEM ANTERIOR,
                            DEVERÁ SER SOLICITADA A SUA EXCLUSÃO DO CALCULO DE
                            VAGAS
                        </li>
                        <li>
                            VAGAS DE ESTACIONAMENTO NO DISTRITO INDUSTRIAL
                            DEVERÃO OBEDECER NORMATIVAS EXPRESSAS NA LEI
                            ORDINÁRIA 1872/1997
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
};
