import React from "react";
import environments from "../../../../environments";
import { StandardAturizacaoAberturaTumulo } from "./providers/standard";

export const AutorizacaoAberturaTumulo: React.FC = () => {
    switch (environments.client.name) {
        default:
            return <StandardAturizacaoAberturaTumulo />;
    }
};
