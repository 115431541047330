import React from "react";
import environments from "../../../../environments";
import { TransladoInternoStandard } from "./providers/standard";

export const TransladoInterno: React.FC = () => {
    switch (environments.client.name) {
        default:
            return <TransladoInternoStandard />;
    }
};
