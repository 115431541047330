import { ICemeteryOwnerResponse } from "../../../../../../../shared/providers/cemetery/entities/owner-cemetery.interface";
import { Input, InputMask, Select } from "../../../../../../global";
import { Cities } from "./cities";
import { Nationalities } from "./nationalities";
import { States } from "./states";

interface Props {
    ownerInfo?: Partial<ICemeteryOwnerResponse>;
}
export const OwnerComponent: React.FC<Props> = ({ ownerInfo }) => {
    return (
        <div>
            <div className="grid gap-1 grid-cols-3 grid-rows-1 relative">
                <div className="col-span-2">
                    <Input
                        name="propnomeForm"
                        label="PROPRIETÁRIO"
                        defaultValue={ownerInfo?.propnome}
                    />
                </div>
                <Select
                    name="propsexoForm"
                    label="SEXO"
                    defaultValue={{
                        label: ownerInfo?.propsexo,
                        value: ownerInfo?.propsexo,
                    }}
                    options={[
                        {
                            label: "FEMININO",
                            value: "FEMININO",
                        },
                        {
                            label: "MASCULINO",
                            value: "MASCULINO",
                        },
                    ]}
                />
                <InputMask
                    name="propnascimentoForm"
                    label="DATA DE NASCIMENTO"
                    mask="99/99/9999"
                    defaultValue={ownerInfo?.propnascimento}
                />
                <Select
                    name="estadocivilForm"
                    label="ESTADO CIVIL"
                    maxMenuHeight={123}
                    defaultValue={{
                        label: ownerInfo?.estado_civil,
                        value: ownerInfo?.estado_civil,
                    }}
                    options={[
                        {
                            label: "SOLTEIRO",
                            value: "SOLTEIRO",
                        },
                        {
                            label: "SOLTEIRA",
                            value: "SOLTEIRA",
                        },
                        {
                            label: "CASADO",
                            value: "CASADO",
                        },
                        {
                            label: "CASADA",
                            value: "CASADA",
                        },
                        {
                            label: "DIVORCIADO",
                            value: "DIVORCIADO",
                        },
                        {
                            label: "DIVORCIADA",
                            value: "DIVORCIADA",
                        },
                        {
                            label: "VIÚVO",
                            value: "VIÚVO",
                        },
                        {
                            label: "VIÚVA",
                            value: "VIÚVA",
                        },
                        {
                            label: "SEPARADO",
                            value: "SEPARADO",
                        },
                        {
                            label: "SEPARADA",
                            value: "SEPARADA",
                        },
                    ]}
                />
                <InputMask
                    name="propdocumentoForm"
                    label="CPF"
                    mask="999.999.999-99"
                    defaultValue={ownerInfo?.propdocumento}
                />
                <Input
                    name="proprgForm"
                    label="RG"
                    defaultValue={ownerInfo?.proprg}
                />

                <Input
                    name="logradouronomeForm"
                    label="LOGRADOURO"
                    defaultValue={ownerInfo?.logradouronome}
                />
                <Input
                    name="logradouronumeroForm"
                    label="Nº LOGRADOURO"
                    defaultValue={ownerInfo?.logradouronumero}
                />
                <Input
                    name="bairronomeForm"
                    label="BAIRRO"
                    defaultValue={ownerInfo?.bairronome}
                />
                <Cities maxMenuHeight={123} city={ownerInfo?.cidade || ""} />

                <States maxMenuHeight={123} state={ownerInfo?.estado || ""} />

                <Nationalities
                    maxMenuHeight={123}
                    nationality={ownerInfo?.nacionalidade || ""}
                />

                <InputMask
                    name="telefoneForm"
                    label="TELEFONE"
                    mask="(99)99999-9999"
                    defaultValue={ownerInfo?.telefone}
                />
                <Input
                    name="emailForm"
                    label="E-MAIL"
                    defaultValue={ownerInfo?.email}
                />

                <Input
                    name="numerotermoposseForm"
                    label="NÚMERO DO TERMO DE POSSE"
                    defaultValue={ownerInfo?.id}
                    disabled
                    hidden
                />
            </div>
        </div>
    );
};
