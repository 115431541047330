import * as Yup from "yup";
import { ICemeteryTransferredIntern } from "../../../../../../../shared/providers/cemetery/entities/transferred.interface";

const schema = Yup.object().shape({
    id_falecido: Yup.number().required("Selecione um falecido"),
    controle: Yup.string().required("Campo CONTROLE é obrigatório"),
    lote: Yup.string().required("Campo LOTE é obrigatório"),
    quadra: Yup.string().required("Campo QUADRA é obrigatório"),
    nome: Yup.string().required("Campo NOME DO FALECIDO é obrigatório"),
    tipo_translado: Yup.string().required(
        "Campo TIPO DE TRANSLADO é obrigatório"
    ),
    inscricao: Yup.string().required(),
    tipo_sepultamento_anterior: Yup.string().required(),
});

export const transferredInternValidator = async (
    payload: ICemeteryTransferredIntern
) => {
    await schema.validate(payload, {
        abortEarly: false,
    });
};
