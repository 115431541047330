import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IItbi } from "../../../../../shared/providers/itbi/interfaces";
import { DotLine } from "../../../bci/styles";
import { dateMask } from "../../../../../shared/utils/mask.utils";

interface Props {
    ano: string;
    data?: IItbi[];
}

export const ItbiHistory: React.FC<Props> = ({ ano, data }) => {
    if (!data) {
        return <h4>Não encontramos nenhuma informação para este lote</h4>;
    }
    return (
        <>
            <Row>
                <h4>Informações do ano de {ano}</h4>
            </Row>
            {data &&
                data.map(
                    (value, index) =>
                        value.exercicio === ano && (
                            <div key={`${value.id}`}>
                                {index > 0 && <DotLine />}
                                <Row>
                                    <>
                                        <Col>
                                            <Form.Label>
                                                Data Lançamento
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                value={dateMask(
                                                    value.datalancamento,
                                                    "date"
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>Valor</Form.Label>
                                            <Form.Control
                                                disabled
                                                value={value?.valor}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                Valor (Financiado)
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                value={value?.valorfinanciado}
                                            />
                                        </Col>
                                    </>
                                </Row>
                                <Row>
                                    <>
                                        <Col>
                                            <Form.Label>
                                                Base de cálculo
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                value={value.basecalculo}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label>
                                                Base de cálculo (Financiado)
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                value={
                                                    value.basecalculofinanciado
                                                }
                                            />
                                        </Col>
                                    </>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Observação</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            disabled
                                            value={value.observacao}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Complemento</Form.Label>
                                        <Form.Control
                                            disabled
                                            value={value.complemento}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )
                )}
        </>
    );
};
