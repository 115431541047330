/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Col, Nav, Row, Tab } from "react-bootstrap";

import { ItbiEdit } from "./tabs/edit";

import { IItbi } from "../../../../shared/providers/itbi/interfaces";
import { ItbiApiService } from "../../../../shared/services/api/itbi-api.service";
import { ItbiHistory } from "./tabs/history";

interface ItbiInfoProps {
    info: Record<string, string>;
}

export const ItbiInfo: React.FC<ItbiInfoProps> = ({ info }) => {
    const [itbis, setItbis] = useState<IItbi[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchItbi = async () => {
            if (!info) return;

            setLoading(true);
            try {
                const itbisList = await ItbiApiService.getItbi(
                    info.cadastrogeral
                );
                setItbis(itbisList.data);
            } finally {
                setLoading(false);
            }
        };

        if (!loading && !itbis.length) {
            fetchItbi();
        }
    }, []);

    if (!itbis.length && !loading)
        return <h4>Nenhuma informação encontrada</h4>;

    if (loading) return null;

    return (
        <Tab.Container defaultActiveKey="last-year">
            <Row>
                <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item as="button">
                            <Nav.Link eventKey="last-year">ITBI 2024</Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="button">
                            <Nav.Link eventKey="history-2023">
                                HISTÓRICO ITBI (2023)
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="button">
                            <Nav.Link eventKey="history-2022">
                                HISTÓRICO ITBI (2022)
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item as="button">
                            <Nav.Link eventKey="editable">
                                ADICIONAR/EDITAR
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>

                <Col>
                    <Tab.Content
                        style={{
                            height: "400px",
                            margin: 0,
                            overflowX: "hidden",
                            overflowY: "auto",
                        }}
                    >
                        <Tab.Pane eventKey="last-year">
                            <ItbiHistory data={itbis} ano="2024" />
                        </Tab.Pane>

                        <Tab.Pane eventKey="history-2023">
                            <ItbiHistory data={itbis} ano="2023" />
                        </Tab.Pane>

                        <Tab.Pane eventKey="history-2022">
                            <ItbiHistory data={itbis} ano="2022" />
                        </Tab.Pane>

                        <Tab.Pane eventKey="editable">
                            <ItbiEdit data={itbis} />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
};
