import React from "react";
import { ICemeteryResponse } from "../../../../../shared/providers/cemetery/entities/cemetery.interface";
import { SearchFormActionLote } from "./actions-lote";

interface PropertiesTableProps {
    doAfterActionsClick?(): void;
    properties: Partial<ICemeteryResponse>[];
}

const HIDDEN_TEXT = "*************";

export const SearchCemeteryLoteTable: React.FC<PropertiesTableProps> = ({
    doAfterActionsClick,
    properties,
}) => {
    return (
        <div className="flex flex-col h-full w-full">
            <div className="overflow-x-auto overflow-y-auto sm:-mx-6 lg:-mx-4">
                <div className="inline-block min-w-full">
                    <div className="overflow-hidden">
                        <table className="min-w-full table-auto border-teal-900">
                            <thead className="border-b">
                                <tr className="bg-gray-100">
                                    <th
                                        scope="col"
                                        className="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                                    >
                                        Ações
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                                    >
                                        INSCRIÇÃO
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-lg font-bold text-gray-900 px-6 py-4 text-left"
                                    >
                                        ID
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {properties.map((property, index) => (
                                    <tr
                                        key={property.id}
                                        className={`${
                                            index % 2 !== 0
                                                ? "bg-gray-100 border-b"
                                                : "bg-white border-b"
                                        }`}
                                    >
                                        {property.geom && (
                                            <td className="px-6 whitespace-nowrap text-sm font-medium text-gray-900 ">
                                                <div className="flex space-x-3.5">
                                                    <SearchFormActionLote
                                                        lote={property.geom}
                                                        doAfterClick={
                                                            doAfterActionsClick
                                                        }
                                                    />
                                                </div>
                                            </td>
                                        )}
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {property.inscricao || HIDDEN_TEXT}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            {property.id || HIDDEN_TEXT}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
