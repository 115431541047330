import * as Yup from "yup";
import { showToast } from "../../components/global/toast";

export function yupValidationErrorByField(err: unknown): {
    [key: string]: string;
} {
    const validationErrorByField: { [key: string]: string } = {};

    if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
            if (error.path) validationErrorByField[error.path] = error.message;
            showToast({ type: "error", message: error.message });
        });
    }

    return validationErrorByField;
}
