/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import {
    Col,
    Form,
    FormGroup,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import { BsArrowLeftSquare } from "react-icons/bs";
import { ICemeteryDeceasedResponse } from "../../../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";

interface ReadFormProps {
    backButton(): void;
    deceasedInfo?: Partial<ICemeteryDeceasedResponse>;
}

export const ReadDeceased: React.FC<ReadFormProps> = ({
    backButton,
    deceasedInfo,
}) => {
    const handleGoBack = () => {
        backButton();
    };
    return (
        <div>
            <div className="grid grid-cols-3 grid-rows-1">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>VOLTAR</Tooltip>}
                >
                    <div className="mb-3 flex space-x-2 justify-center">
                        <button type="button" onClick={handleGoBack}>
                            <BsArrowLeftSquare color="teal" size={20} />
                        </button>
                    </div>
                </OverlayTrigger>
            </div>
            <FormGroup className="w-full md:w-6/6 pr-1" key={deceasedInfo?.id}>
                <Row>
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            FALECIDO
                        </Form.Label>
                        <Form.Control value={deceasedInfo?.nome} disabled />
                    </Col>
                    <Col sm={3}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            SEXO
                        </Form.Label>
                        <Form.Control value={deceasedInfo?.sexo} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            TIPO DE TRANSLADO
                        </Form.Label>
                        <Form.Control
                            value={deceasedInfo?.tipo_cadastramento}
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    {deceasedInfo?.data_nascimento && (
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                DATA DE NASCIMENTO
                            </Form.Label>
                            <Form.Control
                                value={deceasedInfo.data_nascimento}
                                disabled
                            />
                        </Col>
                    )}
                    {deceasedInfo?.data_falecimento && (
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                DATA DE FALECIMENTO
                            </Form.Label>
                            <Form.Control
                                value={deceasedInfo.data_falecimento}
                                disabled
                            />
                        </Col>
                    )}
                    {deceasedInfo?.data_sepultamento && (
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                DATA DE SEPULTAMENTO
                            </Form.Label>
                            <Form.Control
                                value={deceasedInfo.data_sepultamento}
                                disabled
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            NOME DA MÃE
                        </Form.Label>
                        <Form.Control value={deceasedInfo?.nome_mae} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            NOME DO PAI
                        </Form.Label>
                        <Form.Control value={deceasedInfo?.nome_pai} disabled />
                    </Col>
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            NUMERO DA DECLARAÇÃO DE ÓBITO
                        </Form.Label>
                        <Form.Control
                            value={deceasedInfo?.declaracao}
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            NUMERO DO ATESTADO DE ÓBITO
                        </Form.Label>
                        <Form.Control value={deceasedInfo?.atestado} disabled />
                    </Col>
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            CARTÓRIO
                        </Form.Label>
                        <Form.Control value={deceasedInfo?.cartorio} disabled />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            FUNERÁRIA
                        </Form.Label>
                        <Form.Control
                            value={deceasedInfo?.funeraria}
                            disabled
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            LIVRO
                        </Form.Label>
                        <Form.Control value={deceasedInfo?.livro} disabled />
                    </Col>
                    <Col sm={2}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            FOLHA
                        </Form.Label>
                        <Form.Control value={deceasedInfo?.folha} disabled />
                    </Col>
                </Row>
                <Row>
                    {deceasedInfo?.data_exumacao && (
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                DATA DE EXUMAÇÃO
                            </Form.Label>
                            <Form.Control
                                value={deceasedInfo.data_exumacao}
                                disabled
                            />
                        </Col>
                    )}
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            INSCRIÇÃO
                        </Form.Label>
                        <Form.Control
                            value={deceasedInfo?.inscricao}
                            disabled
                        />
                    </Col>
                    <Col>
                        <Form.Label style={{ fontWeight: "bold" }}>
                            CONTROLE
                        </Form.Label>
                        <Form.Control value={deceasedInfo?.controle} disabled />
                    </Col>
                </Row>
            </FormGroup>
        </div>
    );
};
