import React, { useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
    BsArrowLeftSquare,
    BsPencilSquare,
    BsPlusSquare,
} from "react-icons/bs";
import { FeaturesEnum } from "../../../../../../shared/enums/features.enum";
import { useAuth } from "../../../../../../shared/hooks/useAuth";
import { ICemeteryGraveResponse } from "../../../../../../shared/providers/cemetery/entities/grave-cemetery.interface";
import { AddGrave } from "./add";
import { EditGrave } from "./edit";

interface Props {
    doAfterSubmit(subscription: string): void;
    info?: Partial<ICemeteryGraveResponse>;
    infoLayer?: Record<string, string>;
}

export const Grave: React.FC<Props> = ({ doAfterSubmit, info, infoLayer }) => {
    const { user } = useAuth();
    const [enableCreate, setEnableCreate] = useState<boolean>(false);
    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleEnableEdit = () => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
    };
    const handleEnableCreate = () => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
    };

    const handleAfterCreateSubmit = (subscription: string) => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    const handleAfterEditSubmit = (subscription: string) => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    return (
        <>
            <div>
                <div className="grid grid-cols-3 grid-rows-1">
                    {!info ? (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {enableCreate
                                        ? "VOLTAR"
                                        : "ADICIONAR JAZIGO"}
                                </Tooltip>
                            }
                        >
                            <div className="mb-3 flex space-x-2 justify-center">
                                {user.authorizationsByFeatureName[
                                    FeaturesEnum.CEMETERY_GRAVE
                                ]?.canCreate && (
                                    <button
                                        type="button"
                                        onClick={handleEnableCreate}
                                    >
                                        {enableCreate ? (
                                            <BsArrowLeftSquare
                                                color="teal"
                                                size={20}
                                            />
                                        ) : (
                                            <BsPlusSquare
                                                color="teal"
                                                size={20}
                                            />
                                        )}
                                    </button>
                                )}
                            </div>
                        </OverlayTrigger>
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {enableEdit ? "VOLTAR" : "EDITAR JAZIGO"}
                                </Tooltip>
                            }
                        >
                            <div className="mb-3 flex space-x-2 justify-center">
                                {user.authorizationsByFeatureName[
                                    FeaturesEnum.CEMETERY_GRAVE
                                ]?.canUpdate && (
                                    <button
                                        type="button"
                                        onClick={handleEnableEdit}
                                    >
                                        {enableEdit ? (
                                            <BsArrowLeftSquare
                                                color="teal"
                                                size={20}
                                            />
                                        ) : (
                                            <BsPencilSquare
                                                color="teal"
                                                size={20}
                                            />
                                        )}
                                    </button>
                                )}
                            </div>
                        </OverlayTrigger>
                    )}
                </div>
                {enableCreate && (
                    <AddGrave
                        subscription={infoLayer?.inscricao}
                        infoLayer={infoLayer}
                        doAfterSubmit={handleAfterCreateSubmit}
                    />
                )}
                {enableEdit && info && (
                    <EditGrave
                        graveInfo={info}
                        doAfterSubmit={handleAfterEditSubmit}
                    />
                )}
            </div>
            {!enableCreate && !enableEdit && (
                <div>
                    {info && (
                        <Form.Group>
                            <Row>
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        INSCRIÇÃO
                                    </Form.Label>
                                    <Form.Control
                                        value={info.inscricao}
                                        disabled
                                    />
                                </Col>
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        CONTROLE
                                    </Form.Label>
                                    <Form.Control
                                        value={info.controle}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        DISTRITO
                                    </Form.Label>
                                    <Form.Control
                                        value={info.distrito}
                                        disabled
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        SETOR
                                    </Form.Label>
                                    <Form.Control value={info.setor} disabled />
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        QUADRA
                                    </Form.Label>
                                    <Form.Control
                                        value={info.quadra}
                                        disabled
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        LOTE
                                    </Form.Label>
                                    <Form.Control value={info.lote} disabled />
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        Q - LOT
                                    </Form.Label>
                                    <Form.Control
                                        value={info.quadra_lot}
                                        disabled
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        L - LOT
                                    </Form.Label>
                                    <Form.Control
                                        value={info.lote_lot}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        SITUAÇÃO DO JAZIGO
                                    </Form.Label>
                                    <Form.Control
                                        value={info.situacao}
                                        disabled
                                    />
                                </Col>
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        OCUPAÇÃO
                                    </Form.Label>
                                    <Form.Control
                                        value={info.ocupacao}
                                        disabled
                                    />
                                </Col>
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        ESPÉCIE
                                    </Form.Label>
                                    <Form.Control
                                        value={info.especie}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        LARGURA EM (m²)
                                    </Form.Label>
                                    <Form.Control
                                        value={info.largura}
                                        disabled
                                    />
                                </Col>
                                <Col>
                                    <Form.Label style={{ fontWeight: "bold" }}>
                                        COMPRIMENTO EM (m²)
                                    </Form.Label>
                                    <Form.Control
                                        value={info.comprimento}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    )}
                </div>
            )}
        </>
    );
};
