import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { createFilter } from "react-select";
import { SelectOptions } from "../../../../../../../shared/interfaces/ISelectOptions";
import { Select } from "../../../../../../global";
import { IMunicipios } from "./IInterfaces";

interface Props {
    city: string;
    maxMenuHeight?: number;
}
export const Cities: React.FC<Props> = ({ city, maxMenuHeight }) => {
    const [options, setOptions] = useState<SelectOptions[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const convertOptions = async (opts: IMunicipios[]) => {
        const selectOptions: SelectOptions[] = [];

        opts.forEach((element: IMunicipios) => {
            const option: SelectOptions = {
                label: element.nome.toUpperCase(),
                value: element.nome.toUpperCase(),
            };
            selectOptions.push(option);
        });
        return selectOptions;
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get<IMunicipios[]>(
                "https://servicodados.ibge.gov.br/api/v1/localidades/municipios"
            );
            const op = await convertOptions(response.data);
            setOptions(op);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            {!loading && (
                <Select
                    filterOption={createFilter({
                        ignoreAccents: false,
                        ignoreCase: true,
                    })}
                    defaultValue={{
                        label: city,
                        value: city,
                    }}
                    name="cidadeForm"
                    label="CIDADE"
                    placeholder="Clique para selecionar..."
                    options={options}
                    maxMenuHeight={maxMenuHeight || 205}
                    isClearable
                />
            )}
        </div>
    );
};
