import styled from "styled-components";

interface BackgroundProps {
    show?: boolean;
}

export const Background = styled.div<BackgroundProps>`
    background-color: rgba(255, 255, 255, 1);
    border: 2px solid teal;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: ${(props) => (props.show ? "flex" : "none")};

    height: 40%;
    width: 40%;

    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
