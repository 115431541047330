import React from "react";
import environments from "../../../../environments";

import { StandardBcm } from "./providers/standard";
import { IpirangaBcm } from "./providers/ipiranga";

export const Bcm: React.FC = () => {
    switch (environments.client.name) {
        case "ipiranga":
            return <IpirangaBcm />;
        default:
            return <StandardBcm />;
    }
};
