import * as Yup from "yup";
import { ICemeteryWorkers } from "../../../../../../../shared/providers/cemetery/entities/workers-cemetery.interface";
import { Regex } from "../../../../../../../shared/utils/regex";

const schema = Yup.object().shape({
    tipo_servico: Yup.string().required("Campo TIPO DO SERVIÇO é obrigatório"),
    tipo_prestador: Yup.string().required(
        "Campo TIPO DO PRESTADOR é obrigatório"
    ),
    nome_colaborador: Yup.string().required(
        "Campo NOME DO COLABORADOR é obrigatório"
    ),
    cpf_colaborador: Yup.string()
        .matches(Regex.CPF_CNPJ, "CPF DO COLABORADOR inválido")
        .required("Campo CPF DO COLABORADOR é obrigatório"),
    telefone_colaborador: Yup.string().required(
        "Campo TELEFONE DO COLABORADOR é obrigatório"
    ),
    email_colaborador: Yup.string()
        .email("Informe um EMAIL DO COLABORADOR válido")
        .required("Campo EMAIL DO COLABORADOR é obrigatório"),
    funcao_colaborador: Yup.string().required(
        "Campo FUNÇÃO DO COLABORADOR  é obrigatório"
    ),
    inscricao: Yup.string().required("Campo INSCRIÇÃO é obrigatório"),
});

export const workerOwnerValidator = async (payload: ICemeteryWorkers) => {
    await schema.validate(payload, {
        abortEarly: false,
    });
};
