import React from "react";

export const PalmeiraEstacionamento: React.FC = () => {
    return (
        <>
            <h5>
                VAGAS PARA ESTACIONAMENTO LC 14/2019 - LC 15/2019 - LC 23/2022
            </h5>
            <div className="font-semibold">
                ► EDIFICAÇÕES RESIDENCIAIS
                <ul className="list-disc list-inside font-thin">
                    <li>Unidade Habitacional Autônoma: 01 vaga</li>
                </ul>
            </div>
            <div>
                <div className="font-semibold">► HABITAÇÕES INSTITUCIONAIS</div>
                <p className="small">
                    ALBERGUE, ALOJAMENTO ESTUDANTIL, CASA DO ESTUDANTE, ASILO,
                    CONVENTO, SEMINÁRIO, INTERNATO E ORFANATO
                </p>
                <ul className="list-disc list-inside">
                    <li>Até 100,00m2: FACULTADO</li>
                    <li>
                        de 100,01m2 ou mais: 01 vaga para cada 50,00m2 de área
                        construída
                    </li>
                </ul>
            </div>
            <div>
                <div className="font-semibold">► HABITAÇÕES TRANSITÓRIAS</div>
                <p className="small">
                    APART-HOTEL, PENSÃO, HOTEL, POUSADA E MOTEL.
                </p>
                <ul className="list-disc list-inside">
                    <li>
                        Área administrativa: 01 vaga para cada 80,00m2 de área
                        construída
                    </li>
                    <li>
                        Demais áreas: 01 vaga para cada 50,00m2 de área
                        construída
                    </li>
                </ul>
            </div>
            <div>
                <div className="font-semibold">► CONDOMÍNIOS</div>
                <p className="small">
                    APART-HOTEL, PENSÃO, HOTEL, POUSADA E MOTEL.
                </p>
                <ul className="list-disc list-inside">
                    <li>Unidade Habitacional Autônoma: 01 vaga</li>
                    <li>Unidade Comercial Autônoma: 01 vaga</li>
                </ul>
            </div>

            <div className="font-semibold">► EDIFICAÇÕES COMERCIAIS</div>

            <ul className="pl-0 list-none list-outside">
                <li>
                    ► COMÉRCIO
                    <ul className="list-disc list-inside">
                        <li>
                            Área construída igual ou maior a 500,01m2:
                            <ul className="list-disc list-inside">
                                <li>1 vaga para cada 50,00m2</li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li>
                    ► COMÉRCIO
                    <ul className="list-disc list-inside">
                        <li>
                            Área construída igual ou maior a 500,01m2:
                            <ul className="list-disc list-inside">
                                <li>1 vaga para cada 50,00m2</li>
                                <li>
                                    Área de pátio de carga e descarga
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Até 1.000,00m2 acrescer 100,00m2 de
                                            área de manobra
                                        </li>
                                        <li>
                                            Até 2.000,00m2 acrescer 225,00m2 de
                                            área de manobra
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Acima 2.000,00m2
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Acrescer 225,00m2 de área de manobra
                                            e área de manobra
                                        </li>
                                        <li>
                                            Acrescer mais 150,00m2 a cada
                                            1.000,00m2
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    ► CENTRO COMERCIAL, SHOPPING CENTER SUPERMERCADO E
                    HIPERMERCADO
                    <ul className="list-disc list-inside">
                        <li>Área de vendas: 01 vaga para cada 12,50m2</li>
                        <li>
                            Área de pátio de carga e descarga
                            <ul className="list-disc list-inside">
                                <li>1 vaga para cada 50,00m2</li>
                                <li>
                                    Área de pátio de carga e descarga
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Até 1.000,00m2 acrescer 100,00m2 de
                                            área de manobra
                                        </li>
                                        <li>
                                            Até 2.000,00m2 acrescer 225,00m2 de
                                            área de manobra
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Acima 2.000,00m2
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Acrescer 225,00m2 de área de manobra
                                            e
                                        </li>
                                        <li>
                                            Acrescer mais 150,00m2 a cada
                                            1.000,00m2
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    ► RESTAURANTE, CONFEITARIA, CAFÉS E LANCHONETE:
                    <ul className="list-disc list-inside">
                        <li>01 vaga para cada 12,50m2</li>
                    </ul>
                </li>
                <li>
                    ► COMERCIO ATACADISTA EM GERAL: Área de
                    estacionamento/espera igual ou maior a 40% da área
                    construída
                    <ul className="list-disc list-inside">
                        <li>
                            Área de pátio de carga e descarga
                            <ul className="list-disc list-inside">
                                <li>
                                    Até 2.000,00m2 acrescer 225,00m2 de área de
                                    manobra
                                </li>
                                <li>
                                    Acima 2.000,00m2
                                    <ul className="list-disc list-inside">
                                        <li>
                                            Acrescer 225,00m2 de área de manobra
                                            e
                                        </li>
                                        <li>
                                            Acrescer mais 150,00m2 a cada
                                            1.000,00m2
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <div className="font-semibold">► EDIFICAÇÕES INDUSTRIAIS</div>
            <ul className="list-disc list-inside">
                <li>
                    INDUSTRIA EM GERAL:
                    <ul>
                        <li>1 vaga para cada 150,00m2</li>
                        <li>
                            Área de pátio de carga e descarga (excluído área de
                            estoque)
                            <ul>
                                <li>
                                    Até 1.000,00m2: acrescer 100,00m2 de área de
                                    manobra
                                </li>
                                <li>
                                    Até 2.000,00m2: acrescer 225,00m2 de área de
                                    manobra
                                </li>
                                <li>
                                    Acima 2.000,00m2
                                    <ul>
                                        <li>
                                            Acrescer 225,00m2 de área de manobra
                                            e
                                        </li>
                                        <li>
                                            Acrescer mais 150,00m2 a cada
                                            1.000,00m2
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <div className="font-semibold">► HABITAÇÕES ESPECIAIS</div>
            <ul className="pl-0 list-none list-outside font-semibold">
                AUDITÓRIO, TEATRO, ANFITEATRO, CINEMA, SALÃO DE EXPOSIÇÕES,
                BIBLIOTECA E MUSEU
                <li className="font-light">
                    Área destinada aos espectadores: 01 vaga para cada 25,00m2
                    de área construída
                </li>
            </ul>
            <ul className="pl-0 list-none list-outside font-semibold">
                CLUBE SOCIAL/ESPORTIVO, GINÁSIO DE ESPORTES, ESTÁDIO, ACADEMIA
                <li className="font-light">
                    01 vaga para cada 25,00m2 de área construída
                </li>
            </ul>
            <ul className="pl-0 list-none list-outside font-semibold">
                CANCHA POLIESPORTIVA
                <li className="font-light">
                    01 vaga para cada 25,00m2 de área construída
                </li>
            </ul>
            <ul className="pl-0 list-none list-outside font-semibold">
                TEMPLO, CAPELA, CASA DE CULTO E IGREJA
                <li className="font-light">
                    01 vaga para cada 25,00m2 de área construída
                </li>
            </ul>
        </>
    );
};
