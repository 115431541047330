import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { CemeteryInfoApiService } from "../../../../../../../../shared/services/api/cemetery-api.service";
import { ApiErrorHandler } from "../../../../../../../../shared/utils/errors.utils";
import { Input, MainButton } from "../../../../../../../global";
import { showToast } from "../../../../../../../global/toast";
import { Cities } from "../../../owner/components/cities";
import { States } from "../../../owner/components/states";
import {
    ICemeteryFunerary,
    ICemeteryFuneraryForm,
    ICemeteryFuneraryResponse,
} from "../../../../../../../../shared/providers/cemetery/entities/funerary.interface";

interface Props {
    infoFuneral?: ICemeteryFuneraryResponse;
    doAfterSubmit(): void;
}
export const AddFuneral: React.FC<Props> = ({ infoFuneral, doAfterSubmit }) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async ({
        nomeForm,
        cidadeForm,
        estadoForm,
    }: ICemeteryFuneraryForm) => {
        const payload: ICemeteryFunerary = {
            nome: nomeForm,
            cidade: cidadeForm,
            estado: estadoForm,
        };
        try {
            setLoading(true);

            await CemeteryInfoApiService.createFuneral(payload);

            showToast({
                type: "success",
                message: "Dados da funerária foram adicionados com sucesso",
            });

            doAfterSubmit();
        } catch (err) {
            ApiErrorHandler(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-50">
            <p>Adicionar Funerária</p>
            {!loading && (
                <Form
                    onSubmit={handleSubmit}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <div className="grid gap-1 grid-cols-3 grid-rows-1">
                        <Input
                            name="nomeForm"
                            label="NOME"
                            defaultValue={infoFuneral?.nome}
                        />
                        <Cities city={infoFuneral?.cidade || ""} />

                        <States state={infoFuneral?.estado || ""} />
                    </div>
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-48">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};
