import React from "react";
import environments from "../../../../environments";
import { PrestadorProprietarioStandard } from "./providers";

export const PrestadorProprietario: React.FC = () => {
    switch (environments.client.name) {
        default:
            return <PrestadorProprietarioStandard />;
    }
};
