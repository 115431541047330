import React from "react";

export const StandardEstacionamento: React.FC = () => {
    return (
        <>
            <div>Estacionamento</div>
            <div>Estacionamento</div>
            <div>Estacionamento</div>
            <div>Estacionamento</div>/
        </>
    );
};
