import { IconType } from "react-icons";

import { GiGraveFlowers, GiTombstone } from "react-icons/gi";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdPersonOutline } from "react-icons/md";

export default {
    propnome: {
        label: "Proprietário do Jazigo",
        icon: MdPersonOutline,
    },
    controle: {
        label: "Campo Controle",
        icon: IoIosInformationCircleOutline,
    },
    inscricao: {
        label: "Inscrição do Jazigo",
        icon: GiTombstone,
    },
    nome: {
        label: "Nome do Falecido",
        icon: GiGraveFlowers,
    },
} as Record<string, { label: string; icon: IconType }>;
