import React, { useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
    BsArrowLeftSquare,
    BsPlusSquare,
    BsPencilSquare,
} from "react-icons/bs";
import { FeaturesEnum } from "../../../../../../shared/enums/features.enum";
import { useAuth } from "../../../../../../shared/hooks/useAuth";
import { ICemeteryOwnerResponse } from "../../../../../../shared/providers/cemetery/entities/owner-cemetery.interface";
import { AddOwner } from "./add";
import { EditOwner } from "./edit";

interface Props {
    doAfterSubmit(subscription: string): void;
    info?: Partial<ICemeteryOwnerResponse>;
    infoLayer?: Record<string, string>;
}

export const Owner: React.FC<Props> = ({ doAfterSubmit, info, infoLayer }) => {
    const { user } = useAuth();
    const [enableCreate, setEnableCreate] = useState<boolean>(false);
    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleEnableEdit = () => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
    };
    const handleEnableCreate = () => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
    };

    const handleAfterCreateSubmit = (subscription: string) => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    const handleAfterEditSubmit = (subscription: string) => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    return (
        <>
            <div className="grid grid-cols-3">
                {!info || Object.keys(info).length === 0 ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {enableCreate ? "VOLTAR" : "NOVO PROPRIETÁRIO"}
                            </Tooltip>
                        }
                    >
                        <div className="mb-3 flex space-x-2 justify-center">
                            {user.authorizationsByFeatureName[
                                FeaturesEnum.CEMETERY_OWNER
                            ]?.canCreate && (
                                <button
                                    type="button"
                                    onClick={handleEnableCreate}
                                >
                                    {enableCreate ? (
                                        <BsArrowLeftSquare
                                            color="teal"
                                            size={20}
                                        />
                                    ) : (
                                        <BsPlusSquare color="teal" size={20} />
                                    )}
                                </button>
                            )}
                        </div>
                    </OverlayTrigger>
                ) : (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {enableEdit ? "VOLTAR" : "EDITAR PROPRIETÁRIO"}
                            </Tooltip>
                        }
                    >
                        <div className="mb-3 flex space-x-2 justify-center">
                            {user.authorizationsByFeatureName[
                                FeaturesEnum.CEMETERY_OWNER
                            ]?.canUpdate && (
                                <button
                                    type="button"
                                    onClick={handleEnableEdit}
                                >
                                    {enableEdit ? (
                                        <BsArrowLeftSquare
                                            color="teal"
                                            size={20}
                                        />
                                    ) : (
                                        <BsPencilSquare
                                            color="teal"
                                            size={20}
                                        />
                                    )}
                                </button>
                            )}
                        </div>
                    </OverlayTrigger>
                )}
            </div>
            {enableCreate && (
                <AddOwner
                    subscription={infoLayer?.inscricao}
                    doAfterSubmit={handleAfterCreateSubmit}
                />
            )}
            {enableEdit && (
                <EditOwner
                    ownerInfo={info}
                    doAfterSubmit={handleAfterEditSubmit}
                />
            )}
            {!enableEdit &&
                !enableCreate &&
                info &&
                Object.keys(info).length !== 0 && (
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    PROPRIETÁRIO
                                </Form.Label>
                                <Form.Control value={info?.propnome} disabled />
                            </Col>
                            <Col sm={2}>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    SEXO
                                </Form.Label>
                                <Form.Control value={info?.propsexo} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    CPF
                                </Form.Label>
                                <Form.Control
                                    value={info?.propdocumento}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    RG
                                </Form.Label>
                                <Form.Control value={info?.proprg} disabled />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    DATA DE NASCIMENTO
                                </Form.Label>
                                <Form.Control
                                    value={info?.propnascimento}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    LOGRADOURO
                                </Form.Label>
                                <Form.Control
                                    value={info?.logradouronome}
                                    disabled
                                />
                            </Col>
                            <Col sm={2}>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    NÚMERO
                                </Form.Label>
                                <Form.Control
                                    value={info?.logradouronumero}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    BAIRRO
                                </Form.Label>
                                <Form.Control
                                    value={info?.bairronome}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    CIDADE
                                </Form.Label>
                                <Form.Control value={info?.cidade} disabled />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    ESTADO
                                </Form.Label>
                                <Form.Control value={info?.estado} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TELEFONE
                                </Form.Label>
                                <Form.Control value={info?.telefone} disabled />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    E-MAIL
                                </Form.Label>
                                <Form.Control value={info?.email} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    NACIONALIDADE
                                </Form.Label>
                                <Form.Control
                                    value={info?.nacionalidade}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    ESTADO CIVIL
                                </Form.Label>
                                <Form.Control
                                    value={info?.estado_civil}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Col>
                            <Form.Label style={{ fontWeight: "bold" }}>
                                NÚMERO DO TERMO DE POSSE
                            </Form.Label>
                            <Form.Control value={info?.id} disabled />
                        </Col>
                    </Form.Group>
                )}
        </>
    );
};
