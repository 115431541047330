/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { FormCheck } from "react-bootstrap";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { MainButton } from "../../../../../global";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import {
    ICemeteryCompaniesResponse,
    ICemeteryWorkerOwnerForm,
    ICemeteryWorkers,
    ICemeteryWorkersCompany,
    ICemeteryWorkersResponse,
} from "../../../../../../shared/providers/cemetery/entities/workers-cemetery.interface";
import { yupValidationErrorByField } from "../../../../../../shared/utils/yup-error-by-field";
import { workerOwnerValidator } from "./components/workerOwnerValidator";
import { WorkerComponent } from "./components/workerComponent";
import { WorkerCompanyComponent } from "./components/workerCompanyComponent";

const labelStyle = { fontSize: "15px", color: "#234e52" };

interface AddFormProps {
    doAfterSubmit(subscription: string): void;
    subscription?: string | undefined;
    workersInfo?: Partial<ICemeteryWorkersResponse>;
}
export const AddWorkers: React.FC<AddFormProps> = ({
    doAfterSubmit,
    subscription,
    workersInfo,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [serviceType, setServiceType] = useState<"PROPRIETÁRIO" | "EMPRESA">(
        "PROPRIETÁRIO"
    );
    const [companyFetched, setCompanyFetched] =
        useState<Partial<ICemeteryCompaniesResponse>>();

    const [currentCompany, setCurrentCompany] = useState<string>();

    const handleSubmitOwner = async ({
        tiposervicoForm,
        cadastramentoForm,
        nomecolaboradorForm,
        cpfcolaboradorForm,
        telefonecolaboradorForm,
        emailcolaboradorForm,
        funcaocolaboradorForm,
    }: ICemeteryWorkerOwnerForm) => {
        const payload: ICemeteryWorkers = {
            tipo_servico: tiposervicoForm,
            data_cadastramento: cadastramentoForm,
            tipo_prestador: serviceType,
            nome_colaborador: nomecolaboradorForm,
            cpf_colaborador: cpfcolaboradorForm,
            telefone_colaborador: telefonecolaboradorForm,
            email_colaborador: emailcolaboradorForm,
            funcao_colaborador: funcaocolaboradorForm,
            inscricao: subscription || "",
        };
        try {
            setLoading(true);
            await workerOwnerValidator(payload);

            await CemeteryInfoApiService.createWorker(payload);

            showToast({
                type: "success",
                message: "Dados dos prestadores foram inseridos com sucesso",
            });

            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };
    const handleSubmitCompany = async ({
        cadastramentoForm,
        nomecolaboradorForm,
        cpfcolaboradorForm,
        telefonecolaboradorForm,
        emailcolaboradorForm,
        funcaocolaboradorForm,
        responsavelempresaForm,
        documentoempresaForm,
        razaosocialempresaForm,
        tiposervicoempresaForm,
        telefoneempresaForm,
        emailempresaForm,
    }: {
        tiposervicoForm: string;
        cadastramentoForm: string;
        nomecolaboradorForm: string;
        cpfcolaboradorForm: string;
        telefonecolaboradorForm: string;
        emailcolaboradorForm: string;
        funcaocolaboradorForm: string;
        responsavelempresaForm: string;
        documentoempresaForm: string;
        razaosocialempresaForm: string;
        tiposervicoempresaForm: string;
        telefoneempresaForm: string;
        emailempresaForm: string;
    }) => {
        const payload: ICemeteryWorkersCompany = {
            tipo_servico: tiposervicoempresaForm,
            data_cadastramento: cadastramentoForm,
            tipo_prestador: serviceType,
            nome_colaborador: nomecolaboradorForm,
            cpf_colaborador: cpfcolaboradorForm,
            telefone_colaborador: telefonecolaboradorForm,
            email_colaborador: emailcolaboradorForm,
            funcao_colaborador: funcaocolaboradorForm,
            responsavel_empresa: responsavelempresaForm,
            documento_empresa: documentoempresaForm,
            razao_social_empresa: razaosocialempresaForm,
            tipo_servico_empresa: tiposervicoempresaForm,
            telefone_empresa: telefoneempresaForm,
            email_empresa: emailempresaForm,
            inscricao: subscription || "",
        };
        try {
            setLoading(true);

            await CemeteryInfoApiService.createWorker(payload);

            showToast({
                type: "success",
                message: "Dados dos prestadores foram inseridos com sucesso",
            });

            doAfterSubmit(subscription as string);
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    const handleRelatedByChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setServiceType(event.target.value as "PROPRIETÁRIO" | "EMPRESA");
        if (event.target.value as "PROPRIETÁRIO") {
            setCompanyFetched(undefined);
        }
    };

    const fetchCompanyInfo = useCallback(async (empresaForm: string) => {
        try {
            setLoading(true);

            const company = await CemeteryInfoApiService.getCompanyById(
                parseInt(empresaForm, 10)
            );

            setCompanyFetched(company);
        } catch (err) {
            ApiErrorHandler(err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (currentCompany) {
            setCompanyFetched(undefined);
            fetchCompanyInfo(currentCompany);
        }
    }, [currentCompany, fetchCompanyInfo]);

    return (
        <div className="bg-gray-50">
            {!loading && (
                <div className="flex space-x-2 ml-2">
                    <p style={labelStyle}>Tipo do Prestador:</p>
                    <FormCheck
                        type="radio"
                        name="serviceTypeOwner"
                        label="PROPRIETÁRIO"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="PROPRIETÁRIO"
                        checked={serviceType === "PROPRIETÁRIO"}
                    />
                    <FormCheck
                        type="radio"
                        name="serviceTypeCompany"
                        label="EMPRESA"
                        onChange={handleRelatedByChange}
                        style={labelStyle}
                        value="EMPRESA"
                        checked={serviceType === "EMPRESA"}
                    />
                </div>
            )}
            {serviceType === "PROPRIETÁRIO" && (
                <Form
                    onSubmit={handleSubmitOwner}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <WorkerComponent workersInfo={workersInfo} />
                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10 mt-2">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}

            {serviceType === "EMPRESA" && (
                <Form
                    onSubmit={handleSubmitCompany}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <WorkerCompanyComponent workersInfo={workersInfo} />

                    <div className="w-full md:w-6/6 flex">
                        <div className="md:w-2/3 h-10">
                            <MainButton type="submit">SALVAR</MainButton>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};
