import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useState, useLayoutEffect } from "react";

import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";

import { PropertiesApiService } from "../../../../shared/services/api/properties-api.service";
import { IBCIResponse } from "../../../../shared/interfaces/IBCI";

import { Header } from "../../../../components/page-releated/bci/header";
import { PropertyCharact } from "../../../../components/page-releated/bci/property-charact";
import { FirstCroqui } from "../../../../components/page-releated/bci/croquis/first-croqui";
import { SecondCroqui } from "../../../../components/page-releated/bci/croquis/second-croqui";
import { Divisor } from "../../../../components/page-releated/bci/styles";
import { useLoading } from "../../../../shared/hooks/useLoading";
import { LayersApiService } from "../../../../shared/services/api/layers-api.service";
import { GlobalStyle } from "../../../../components/page-releated/bci/header/styles";
import { PropertieInfoJcz } from "../../../../components/page-releated/bci/propertie-info/jacarezinho";
import { TestadaJacarezinho } from "../../../../components/page-releated/bci/testadas/jacarezinho";
import { IProperty } from "../../../../shared/interfaces/IProperties";
import { ValuesJacarezinho } from "../../../../components/page-releated/bci/values/jacarezinho";

export const JacarezinhoBci: React.FC = () => {
    const { setLoading } = useLoading();

    const { subscription } = useParams<{
        subscription: string | undefined;
    }>();

    const [bci, setBci] = useState<IBCIResponse>();
    const [lastValues, setLastValues] = useState<IProperty>();

    const fetchBci = useCallback(async () => {
        try {
            setLoading(true);

            if (!subscription) return;

            const [propertyResponse, layersResponse, lastValuesResponse] =
                await Promise.all([
                    PropertiesApiService.getPropertyBci(subscription),
                    LayersApiService.getLayers(
                        `where={"cam_ativa_bci":"true"}&`
                    ),
                    PropertiesApiService.getProperty({
                        subscription,
                        relations: ["lastvalues"],
                    }),
                ]);
            setLastValues(lastValuesResponse);
            setBci({ ...propertyResponse, layers: layersResponse.data });
        } finally {
            setLoading(false);
        }
    }, [subscription, setLoading]);

    useLayoutEffect(() => {
        fetchBci();
    }, [fetchBci]);

    return (
        <>
            <GlobalStyle />
            <Container>
                <Header subscription={subscription || ""} />
                <div>
                    {bci ? (
                        <>
                            <Divisor />

                            <FirstCroqui
                                layers={bci.layers}
                                propertyGeom={bci.geom}
                            />

                            <Divisor />

                            <PropertieInfoJcz property={bci.property} />

                            <Divisor />

                            <PropertyCharact charact={bci.characteristics} />

                            <Divisor />

                            <TestadaJacarezinho
                                testadas={bci.testadas}
                                property={bci.property}
                            />

                            <Divisor />

                            <ValuesJacarezinho
                                values={bci.values}
                                lastValues={lastValues?.lastvalues}
                            />

                            <Divisor />

                            <SecondCroqui
                                layers={bci.layers}
                                propertyGeom={bci.geom}
                            />
                        </>
                    ) : null}

                    <Divisor />
                </div>
            </Container>
        </>
    );
};
