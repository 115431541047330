import React from "react";
import { Row } from "react-bootstrap";
import { IAlvara } from "../../../../shared/interfaces/IBCI";

interface Props {
    alvara?: IAlvara;
}

export const PalmeiraAlvaras: React.FC<Props> = ({ alvara }) => {
    return (
        <>
            <p className="font-semibold">ALVARÁS EMITIDOS ► SMDU | SMGPF</p>
            <Row>
                <table className="table">
                    <tbody>
                        <tr
                            className="font-bold"
                            style={{ background: "#D0CECE" }}
                        >
                            <td>CONSTRUÇÃO</td>
                            <td>PROCESSO</td>
                            <td>DESCRIÇÃO</td>
                            <td>ÁREA CONSTRUIR</td>
                            <td>CVCO</td>
                        </tr>
                        <tr
                            className="border-b-4 border-white"
                            style={{ background: "#E7E6E6" }}
                        >
                            <td>{alvara?.numero_alvara_ac || "-"}</td>
                            <td>{alvara?.processo_ac || "-"}</td>
                            <td>{alvara?.descricao_ac || "-"}</td>
                            <td>{alvara?.area_da_obra_ac || "-"}</td>
                            <td>{alvara?.cvco_ac || "-"}</td>
                        </tr>
                        <tr
                            className="font-bold"
                            style={{ background: "#D0CECE" }}
                        >
                            <td>DEMOLIÇÃO</td>
                            <td>PROCESSO</td>
                            <td>DESCRIÇÃO</td>
                            <td>ÁREA DEMOLIR</td>
                            <td>CVCO</td>
                        </tr>
                        <tr
                            className="border-b-4 border-white"
                            style={{ background: "#E7E6E6" }}
                        >
                            <td>{alvara?.numero_alvara_ad || "-"}</td>
                            <td>{alvara?.processo_ad || "-"}</td>
                            <td>{alvara?.descricao_ad || "-"}</td>
                            <td>{alvara?.area_demolir_ad || "-"}</td>
                            <td>{alvara?.cvco_ad || "-"}</td>
                        </tr>
                        <tr
                            className="font-bold"
                            style={{ background: "#D0CECE" }}
                        >
                            <td>REFORMA/AMPLIAÇÃO</td>
                            <td>PROCESSO</td>
                            <td>DESCRIÇÃO</td>
                            <td>ÁREA DA OBRA</td>
                            <td>CVCO</td>
                        </tr>
                        <tr
                            className="border-b-4 border-white"
                            style={{ background: "#E7E6E6" }}
                        >
                            <td>{alvara?.numero_alvara_ar || "-"}</td>
                            <td>{alvara?.processo_ar || "-"}</td>
                            <td>{alvara?.descricao_ar || "-"}</td>
                            <td>{alvara?.area_da_obra_ar || "-"}</td>
                            <td>{alvara?.cvco_ar || "-"}</td>
                        </tr>
                    </tbody>
                </table>
            </Row>
        </>
    );
};
