import React from "react";
import environments from "../../../../environments";
import { PrestadorEmpresaStandard } from "./providers/standard";

export const PrestadorEmpresa: React.FC = () => {
    switch (environments.client.name) {
        default:
            return <PrestadorEmpresaStandard />;
    }
};
