import * as Yup from "yup";
import { ICemeteryOwner } from "../../../../../../../shared/providers/cemetery/entities/owner-cemetery.interface";
import { Regex } from "../../../../../../../shared/utils/regex";

const schema = Yup.object().shape({
    propsexo: Yup.string().required("Campo SEXO é obrigatório"),
    propnome: Yup.string().required("Campo NOME é obrigatório"),
    propdocumento: Yup.string()
        .matches(Regex.CPF_CNPJ, "CPF inválido")
        .required("CPF obrigatório"),
    proprg: Yup.string().required("Campo RG é obrigatório"),
    propnascimento: Yup.string().required(
        "Campo DATA NASCIMENTO é obrigatório"
    ),
    logradouronome: Yup.string().required("Campo LOGRADOURO é obrigatório"),
    bairronome: Yup.string().required("Campo BAIRRO é obrigatório"),
    logradouronumero: Yup.string().required("Campo NÚMERO é obrigatório"),
    cidade: Yup.string().required("Campo CIDADE é obrigatório"),
    estado: Yup.string().required("Campo ESTADO é obrigatório"),
    telefone: Yup.string().required("Campo TELEFONE é obrigatório"),
    email: Yup.string()
        .email("Informe um EMAIL válido")
        .required("Campo EMAIL é obrigatório"),
    nacionalidade: Yup.string().required("Campo NACIONALIDADE é obrigatório"),
    estado_civil: Yup.string().required("Campo ESTADO CIVIL é obrigatório"),
    numero_termo_posse: Yup.string().notRequired(),
    inscricao: Yup.string().required(),
});

export const ownerValidator = async (payload: ICemeteryOwner) => {
    await schema.validate(payload, {
        abortEarly: false,
    });
};
