import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useState, useLayoutEffect } from "react";

import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GlobalStyle } from "../../../../../components/page-releated/cemetery-docs/header/providers/styles";
import { BodyAutorizacaoAberturaTumulo } from "../../../../../components/page-releated/cemetery-docs/autorizacao-abertura-tumulo";
import { useLoading } from "../../../../../shared/hooks/useLoading";
import { ICemeteryResponse } from "../../../../../shared/providers/cemetery/entities/cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../shared/services/api/cemetery-api.service";
import { HeaderCemeteryDocs } from "../../../../../components/page-releated/cemetery-docs/header";

export const StandardAturizacaoAberturaTumulo: React.FC = () => {
    const { setLoading } = useLoading();

    const { subscription, deceased } = useParams<{
        subscription: string | undefined;
        deceased: string | undefined;
    }>();

    const [cemeteryInfo, setCemeteryInfo] = useState<ICemeteryResponse>();

    const fetchInfo = useCallback(async () => {
        try {
            setLoading(true);

            if (!subscription) return;

            // const [propertyResponse, layersResponse] = await Promise.all([
            //     PropertiesApiService.getPropertyBci(subscription),
            //     LayersApiService.getLayers(`where={"cam_ativa_bci":"true"}&`),
            // ]);

            const fetchedInfo =
                await CemeteryInfoApiService.getCemeteryInfoByOwner(
                    subscription,
                    [
                        "taxes",
                        "workers",
                        "deceased",
                        "grave",
                        "transferred",
                        "attachments",
                        "geom",
                    ]
                );

            setCemeteryInfo(fetchedInfo.data[0]);
        } finally {
            setLoading(false);
        }
    }, [subscription, setLoading]);

    useLayoutEffect(() => {
        fetchInfo();
    }, [fetchInfo]);

    return (
        <>
            <GlobalStyle />
            <Container>
                <HeaderCemeteryDocs />
                {cemeteryInfo ? (
                    <div className="mr-14 ml-5">
                        <BodyAutorizacaoAberturaTumulo
                            cemeteryInfo={cemeteryInfo}
                            idDeceased={deceased}
                        />
                    </div>
                ) : (
                    <>
                        PARA IMPRIMIR ESTE DOCUMENTO, É NECESSÁRIO CADASTRAR O
                        PROPRIETÁRIO!
                    </>
                )}
            </Container>
        </>
    );
};
