import React from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { FeaturesEnum } from "../../../../../../shared/enums/features.enum";
import { useAuth } from "../../../../../../shared/hooks/useAuth";
import { RegisterFuneral } from "./components/funeral/funeral";
import { RegisterRegistery } from "./components/registery/registery";

export const RegisterSubComponents: React.FC = () => {
    const { user } = useAuth();

    return (
        <div>
            {user.authorizationsByFeatureName[FeaturesEnum.CEMETERY_SUB_FIELDS]
                ?.canCreate && (
                <Tab.Container defaultActiveKey="registery">
                    <Col>
                        <Row>
                            <Nav variant="pills" className="justify-center">
                                <Nav.Item as="button">
                                    <Nav.Link eventKey="registery">
                                        CARTÓRIO
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="button">
                                    <Nav.Link eventKey="funeral">
                                        FUNERÁRIA
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Row>
                        <Row>
                            <Tab.Content
                                style={{
                                    height: "400px",
                                    margin: 0,
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                }}
                            >
                                <Tab.Pane eventKey="registery">
                                    <RegisterRegistery />
                                </Tab.Pane>
                                <Tab.Pane eventKey="funeral">
                                    <RegisterFuneral />
                                </Tab.Pane>
                            </Tab.Content>
                        </Row>
                    </Col>
                </Tab.Container>
            )}
        </div>
    );
};
