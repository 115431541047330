import React from "react";

export const PalmeiraSolo: React.FC = () => {
    return (
        <div>
            <h5>ALERTAS INCIDENTES SOBRE O USO DO SOLO</h5>

            <ul className="pl-0 list-none list-outside">
                <li>
                    ► OS SERVIÇOS DEFINIDOS NAS CLASSIFICAÇÕES ACIMA ESTÃO
                    EXPRESSOS{" "}
                    <a
                        href="https://d1cge5fflrvk0g.cloudfront.net/palmeira/leis/ANEXO_2.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        NO ANEXO 2
                    </a>{" "}
                    DESTA CONSULTA
                </li>
                <li>
                    ► ALVARÁS DE FUNCIONAMENTO DEVERÃO SER REQUERIDOS JUNTO AO
                    SITE EMPRESA FÁCIL
                </li>
                <li>
                    ► LOTES APROVADOS ATRAVÉS DA ELI 3960/20?? DEVERÃO SEGUIR OS
                    PARÂMTEROS ESTABELECIDOS PELA MESMA
                </li>
                <li>
                    ► RESIDÊNCIAS EM MADEIRA TERÃO SEUS RECUOS LATERAIS E FUNDOS
                    NO MÍNIMO COM 3,00m (TRÊS METROS) DA DIVISA, COM JANELAS.
                </li>
            </ul>
        </div>
    );
};
