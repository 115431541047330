import React from "react";
import { ILayers } from "../../../../../shared/interfaces/ILayers";
import { IPropertyGeom } from "../../../../../shared/interfaces/IProperties";
import { Croqui } from "../../../bci/croquis/map";
import { Container, MapContainer } from "./styles";

export interface Props {
    layers: ILayers[];
    propertyGeom: IPropertyGeom;
}

export const FirstCroquiCemetery: React.FC<Props> = ({
    layers,
    propertyGeom,
}) => {
    return (
        <Container>
            <MapContainer>
                <Croqui
                    layers={layers}
                    propertyGeom={propertyGeom}
                    padding={[120, 120, 120, 120]}
                />
            </MapContainer>
        </Container>
    );
};
