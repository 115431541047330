import * as Yup from "yup";
import { ICemeteryTransferredExtern } from "../../../../../../../shared/providers/cemetery/entities/transferred.interface";

const schema = Yup.object().shape({
    id_falecido: Yup.number().required("Selecione um falecido"),
    inscricao: Yup.string().required(),
    controle: Yup.string().required("Campo CONTROLE é obrigatório"),
    lote: Yup.string().required("Campo LOTE é obrigatório"),
    quadra: Yup.string().required("Campo QUADRA é obrigatório"),
    cemiterio_destino: Yup.string().required(
        "Campo CEMITÉRIO DE DESTINO é obrigatório"
    ),
    jazigo: Yup.string().notRequired(),
    cidade: Yup.string().required("Campo CIDADE é obrigatório"),
    estado: Yup.string().required("Campo ESTADO é obrigatório"),
    pais: Yup.string().required("Campo PAÍS é obrigatório"),
    nome: Yup.string().required("Campo NOME DO FALECIDO é obrigatório"),
    tipo_translado: Yup.string().required(
        "Campo TIPO DE TRANSLADO é obrigatório"
    ),
});
export const transferredExternValidator = async (
    payload: ICemeteryTransferredExtern
) => {
    await schema.validate(payload, {
        abortEarly: false,
    });
};
