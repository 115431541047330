/* eslint-disable import/no-duplicates */
import React from "react";
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";

import { ICemeteryResponse } from "../../../../shared/providers/cemetery/entities/cemetery.interface";
import { ICemeteryWorkersResponse } from "../../../../shared/providers/cemetery/entities/workers-cemetery.interface";
import environments from "../../../../environments";

export interface PrestadorProps {
    cemeteryInfo: ICemeteryResponse;
    idWorker: string | undefined;
}

export const PrestadorEmpresaBody: React.FC<PrestadorProps> = ({
    cemeteryInfo,
    idWorker,
}) => {
    let currentWorker = {} as ICemeteryWorkersResponse | undefined;
    let formattedData = "";

    // const formattedData = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
    //     locale: ptBR,
    // });
    if (cemeteryInfo && cemeteryInfo.workers && idWorker) {
        currentWorker = cemeteryInfo.workers.find(
            (d) => d.id === parseInt(idWorker, 10)
        );
        if (currentWorker?.data_criacao) {
            formattedData = format(
                new Date(currentWorker?.data_criacao),
                "dd 'de' MMMM 'de' yyyy",
                {
                    locale: ptBR,
                }
            );
        }
    }

    return (
        <>
            <div className="w-full flex justify-center ">
                <h4 className="font-bold">AUTORIZAÇÃO DE SERVIÇO EM TÚMULO</h4>
            </div>
            <div>
                <strong>
                    AUTORIZAMOS, {cemeteryInfo.propnome},{" "}
                    {cemeteryInfo.nacionalidade}, {cemeteryInfo.estado_civil} ,
                </strong>{" "}
                domiciliado à rua:
                <strong>
                    {" "}
                    {cemeteryInfo.logradouronome} -{" "}
                    {cemeteryInfo.logradouronumero} - {cemeteryInfo.bairronome}{" "}
                    - {cemeteryInfo.cidade} - {cemeteryInfo.estado}.
                </strong>
                <br />
                Telefone: <strong>{cemeteryInfo.telefone}</strong>
                {currentWorker && (
                    <p>
                        Para realização da{" "}
                        <strong>{currentWorker?.tipo_servico_empresa}</strong>{" "}
                        do túmulo na seguinte localização:{" "}
                        <strong>Quadra: {cemeteryInfo.geom?.quadra}</strong> no{" "}
                        <strong>Lote: {cemeteryInfo.geom?.lote}</strong>, com
                        dimensões de{" "}
                        <strong>
                            {cemeteryInfo.grave?.largura}m² X{" "}
                            {cemeteryInfo.grave?.comprimento}m²
                        </strong>
                        , no {environments.webgeo.cemiterio?.nome}
                        <br />A Empresa{" "}
                        <strong>
                            {currentWorker.razao_social_empresa}
                        </strong>{" "}
                        contratada pelo proprietário(a), está sujeita a cumprir
                        integralmente as normas estabelecidas após a execução da{" "}
                        <strong>{currentWorker.tipo_servico_empresa}</strong>
                        {currentWorker.tipo_servico_empresa === "LIMPEZA" ? (
                            <>. Sendo </>
                        ) : (
                            <>
                                . O calçamento entre os túmulos é obrigatório.
                                Na ausência deste, a medida permitida é de 30cm
                                de cada lado. Além disso, é{" "}
                            </>
                        )}
                        obrigatória a retirada de todos os resíduos gerados
                        durante a realização da{" "}
                        <strong>{currentWorker.tipo_servico_empresa}</strong> e,
                        porventura, que venham a permanecer no local, devem ser
                        descartados em área indicada pela{" "}
                        <strong>ADMINISTRAÇÃO</strong> para acomodação.
                        <br />
                        <strong>
                            Qualquer obstrução de túmulo resultará em
                            advertência e notificação à empresa.{" "}
                        </strong>
                        <br />O prazo estabelecido para a conclusão dos
                        trabalhos é de <strong>20 dias</strong> a partir da data
                        de assinatura desta autorização, não podendo ser
                        prorrogado além do prazo determinado pela Administração,
                        que será responsável pela fiscalização do serviço. A não
                        conformidade com as normas acarretará na impossibilidade
                        de autorização para serviços futuros.
                    </p>
                )}
            </div>
            <div className="w-full flex justify-end">
                <h5 className="font-bold">
                    {environments.webgeo.cemiterio?.municipio}, {formattedData}.
                </h5>
            </div>
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <strong>{environments.webgeo.cemiterio?.responsavel}</strong>
            </div>
            <div className="w-full flex justify-center">
                <strong>
                    {environments.webgeo.cemiterio?.cargoResponsavel ||
                        environments.webgeo.cemiterio?.divisao}
                </strong>
            </div>
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <strong>{cemeteryInfo.propnome}</strong>
            </div>
            <div className="w-full flex justify-center">
                <strong>PROPRIETÁRIO(A)</strong>
            </div>
            <br />
            <div className="w-full flex justify-center">
                <h5 className="font-bold">
                    ________________________________________________________________________
                </h5>
            </div>
            <div className="w-full flex justify-center">
                <strong>{currentWorker?.responsavel_empresa}</strong>
            </div>
            <div className="w-full flex justify-center">
                <strong>RESPONSÁVEL DA EMPRESA</strong>
            </div>
        </>
    );
};
