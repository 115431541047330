import { IEnv } from "../../../shared/interfaces/IEnv";

export default {
    client: {
        name: "ipiranga",
        nomePrefeitura: "Prefeitura Municipal de Ipiranga",
        logo: "https://d1cge5fflrvk0g.cloudfront.net/ipiranga/logos/brasao.png",
        brasao: "https://d1cge5fflrvk0g.cloudfront.net/ipiranga/logos/brasao.png",
        municipio: "| IPIRANGA - PR",
    },
    webgeo: {
        coordinatesPattern: "EPSG:32722",
        bci: {
            nomeSecretaria: "Secretaria Municipal de Gestão Pública e Finanças",
        },
        confrontante: {
            informacoesRodape: "",
            nomeSecretaria: "Secretaria Municipal de Gestão Pública e Finanças",
        },
        consultaPrevia: {
            nomeSecretaria: "Secretaria Municipal de Urbanismo",
            contact: {
                email: "xxx",
                endereco: "xxx",
                horarioAtendimento: "xxx",
                telefone: "xxx",
            },
            leiUsoDeSolo:
                "PARÂMETROS DE USO DO SOLO - Anexo (X) - Lei no XXXX/XXXX",
            leiVagasDeEstacionamento:
                "VAGAS PARA ESTACIONAMENTO anexo à lei XXXX/XXXXX",
        },
        config: {
            bci: true,
            confrontante: false,
            consultaPrevia: false,
            iptu: false,
            laws: false,
            cemiterio: true,
        },
        map: {
            center: [-50.5839503, -25.0253338],
            zoom: 18,
        },
        cemiterio: {
            municipio: "Ipiranga",
            center: [-5630445.661330851, -2877733.71873303],
            nome: "Cemitério Municipal de Ipiranga",
            divisao: "Divisão do Cemitério Municipal de Ipiranga",
        },
    },
    api: {
        url: "https://api-prd.semv.com.br/ipiranga/",
    },
    geoserver: {
        url: "https://geoserversp.semv.com.br/geoserver",
        ortoUrl: "https://geoserver-orto.semv.com.br/geoserver",
    },
} as IEnv;
