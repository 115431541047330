import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { showToast } from "../../../../../global/toast";
import { MainButton } from "../../../../../global";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import {
    ICemeteryOwner,
    ICemeteryOwnerForm,
    ICemeteryOwnerResponse,
} from "../../../../../../shared/providers/cemetery/entities/owner-cemetery.interface";
import { ownerValidator } from "./components/validator";
import { yupValidationErrorByField } from "../../../../../../shared/utils/yup-error-by-field";
import { OwnerComponent } from "./components/ownerComponent";

interface EditFormProps {
    doAfterSubmit(subscription: string): void;
    ownerInfo?: Partial<ICemeteryOwnerResponse>;
}
export const EditOwner: React.FC<EditFormProps> = ({
    doAfterSubmit,
    ownerInfo,
}) => {
    const formRef = useRef<FormHandles>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const id = ownerInfo?.id;

    const handleSubmitEdit = async ({
        propsexoForm,
        propnomeForm,
        propdocumentoForm,
        proprgForm,
        propnascimentoForm,
        logradouronomeForm,
        bairronomeForm,
        logradouronumeroForm,
        cidadeForm,
        estadoForm,
        telefoneForm,
        emailForm,
        nacionalidadeForm,
        estadocivilForm,
        numerotermoposseForm,
    }: ICemeteryOwnerForm) => {
        const payload: ICemeteryOwner = {
            propsexo: propsexoForm,
            propnome: propnomeForm,
            propdocumento: propdocumentoForm,
            proprg: proprgForm,
            propnascimento: propnascimentoForm,
            logradouronome: logradouronomeForm,
            bairronome: bairronomeForm,
            logradouronumero: logradouronumeroForm,
            cidade: cidadeForm,
            estado: estadoForm,
            telefone: telefoneForm,
            email: emailForm,
            nacionalidade: nacionalidadeForm,
            estado_civil: estadocivilForm,
            numero_termo_posse: numerotermoposseForm,
            inscricao: ownerInfo?.inscricao || "",
        };
        try {
            setLoading(true);
            if (id) {
                await ownerValidator(payload);

                await CemeteryInfoApiService.updateOwner(id, payload);

                showToast({
                    type: "success",
                    message: "Dados do proprietário atualizados com sucesso",
                });

                doAfterSubmit(ownerInfo?.inscricao as string);
            }
        } catch (err) {
            ApiErrorHandler(err);

            const validationErrorByField = yupValidationErrorByField(err);

            formRef.current?.setErrors(validationErrorByField);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-gray-50">
            <p>Editar Proprietário</p>
            {!loading && (
                <Form
                    onSubmit={handleSubmitEdit}
                    ref={formRef}
                    className="w-full md:w-6/6"
                >
                    <OwnerComponent ownerInfo={ownerInfo} />
                    <div className="md:w-2/3 h-10 mt-10">
                        <MainButton type="submit">SALVAR</MainButton>
                    </div>
                </Form>
            )}
        </div>
    );
};
